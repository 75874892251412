import React from "react";
import ContactScreen from "../components/tec/contact/contactScreen/contactScreen";

export default function Home() {
  return (
    <>
  <ContactScreen />
       
    </>
  );
}
