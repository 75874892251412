import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { PostMedia } from "../../../utils/types/postManagement";
import { Link } from "react-router-dom";

interface BlogProps {
  postmedia: PostMedia[];
  date: string;
  title: string;
  description: string;
  likes: number;
  comments: number;
}

const Blog: React.FC<BlogProps> = ({
  postmedia,
  date,
  title,
  description,
  likes,
  comments,
}) => {
  return (
    <Card
      sx={{
        maxWidth: 400,
        borderRadius: "20px",
        overflow: "hidden",
        boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s",
        "&:hover": {
          transform: "translateY(-8px)",
          boxShadow: "0px 12px 30px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <CardActionArea>
        {/* Media Section */}
        {postmedia &&
          postmedia.map(
            (media, index) =>
              media.type === "image" && (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    height: "220px",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={media.url}
                    alt={media.name}
                    sx={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      bgcolor: "rgba(0, 0, 0, 0.6)",
                      color: "white",
                      padding: "8px 16px",
                    }}
                  >
                    <Typography variant="body2" fontStyle="italic">
                      {date}
                    </Typography>
                  </Box>
                </Box>
              )
          )}

        {/* Overlay Section */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            opacity: 0,
            transition: "opacity 0.3s ease-in-out",
            "&:hover": { opacity: 1 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 3,
            zIndex: 1,
          }}
        >
          <Typography
            variant="h5"
            component="div"
            fontWeight="bold"
            gutterBottom
            sx={{ textShadow: "1px 1px 4px rgba(0,0,0,0.8)" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 2, lineHeight: 1.6 }}
          >
            {description}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.9rem",
              fontWeight: 500,
              display: "inline-block",
              bgcolor: "rgba(255, 255, 255, 0.1)",
              borderRadius: "8px",
              padding: "4px 12px",
            }}
          >
            Likes: {likes} | Comments: {comments}
          </Typography>
        </Box>
      </CardActionArea>

      {/* Content Section */}
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "16px",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "bold",
            color: "text.primary",
            mb: 1,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: "0.85rem",
            mb: 2,
          }}
        >
          {date}
        </Typography>
        <Link to="/" style={{ textDecoration: "none", width: "100%" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              borderRadius: "12px",
              fontWeight: "bold",
              textTransform: "none",
              padding: "10px 20px",
              fontSize: "1rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "box-shadow 0.3s ease-in-out",
              "&:hover": {
                boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
              },
            }}
          >
            Read More
          </Button>
        </Link>
      </CardContent>
    </Card>
  );
};

export default Blog;