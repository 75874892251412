import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import theme, { darkTheme } from "./styles/theme";
import { persistor, store } from "./redux/store";
import { GlobalStyles } from "./styles/globals";
import Layout from "./components/Layout/Layout";
import Home from "./pages";
import About from "./pages/about";
import Contact from "./pages/Contact";
import { login as ULogin } from "./pages/login";
import Faq from "./pages/faq";
import Blog from "./pages/blogs";
import LivePage from "./pages/live";
import GivePage from "./pages/give";
import ChurchHomePage from "./church/routes/ChurchHomePage";
import ChurchAboutPage from "./church/routes/ChurchAboutPage";
import TestimoniesPage from "./church/routes/TestimoniesPage";
import ChurchEventPage from "./church/routes/ChurchEventPage";
import AnnouncementPage from "./church/routes/AnnouncementsPage";
import PublisherPage from "./church/routes/PublisherPage";
import AppDownload from "./pages/download";
import TV from "./pages/tv";
import MinistryPage from "./pages/ministryPage";
import YouthMinistry from "./components/ministries/youthMinistry";
import MenMinistry from "./components/ministries/menMinistry";
import WomenMinistry from "./components/ministries/womenMinstry";
import ChildrenMinistry from "./components/ministries/childrenMinistry";
import { PersistGate } from "redux-persist/integration/react";
import { Helmet } from "react-helmet";
import { registration as Register } from "./pages/registrationpage";
import RegistrationPage from "./components/Registration/signup";
import { UserProfile } from "./pages/profile";
import ProfileEditPage from "./components/userProfile/profileEditPage";
import { DataDeletionRequest } from "./components/tec/contact/contactScreen/datadeletion";
import PrivacyPolicy from "./components/Privacy/privacypolicy";

export default function App() {
  const [toggleTheme, setToggleTheme] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [light, setLight] = useState(false);

  return (
    <>
      <Helmet>
        <title>The Empowerment Church International</title>
        <meta name="description" content="The Empowerment Revolution" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <ThemeProvider theme={toggleTheme ? darkTheme : theme}>
        <Provider store={store}>
          <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
            <Router>
              <Layout
                toggleTheme={toggleTheme}
                setToggleTheme={setToggleTheme}
                toggle={toggle}
                setToggle={setToggle}
                light={light}
                setLight={setLight}
              >
                <Routes>
                  {/* Main Routes */}
                  <Route path="/" element={<Home />} />
                  <Route path="about" element={<About />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="faq" element={<Faq />} />
                  <Route path="blog" element={<Blog />} />
                  <Route path="live" element={<LivePage />} />
                  <Route path="give" element={<GivePage />} />
                  <Route path="download" element={<AppDownload />} />
                  <Route path="login" element={<ULogin />} />
                  <Route path="register" element={<Register />} />

                  {/* Profile Page Route */}
                  <Route path="profile/:userId" element={<UserProfile />} />
                  <Route path="profile/edit" element={<ProfileEditPage />} />
                  <Route path="datadeletionrequest" element={<DataDeletionRequest />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  {/* TV Routes */}
                  <Route path="tv" element={<TV />}>
                    <Route path="programs" element={<TV />} />
                    <Route path="workers" element={<TV />} />
                    <Route path="about" element={<TV />} />
                  </Route>

                  {/* Dashboard Routes */}
                  <Route path="dashboard">
                    <Route path="administrator"></Route>
                  </Route>

                  {/* Church Routes */}
                  <Route path="church">
                    <Route index element={<ChurchHomePage />} />
                    <Route path="about" element={<ChurchAboutPage />} />
                    <Route path="testimonies" element={<TestimoniesPage />} />
                    <Route path="event" element={<ChurchEventPage />} />
                    <Route
                      path="announcements"
                      element={<AnnouncementPage />}
                    />
                    <Route path="publisher" element={<PublisherPage />} />
                    <Route path="ministries">
                      <Route index element={<MinistryPage />} />
                      {/* Ministries Routes */}
                      <Route path="youth" element={<YouthMinistry />} />
                      <Route path="men" element={<MenMinistry />} />
                      <Route path="women" element={<WomenMinistry />} />
                      <Route path="children" element={<ChildrenMinistry />} />
                    </Route>
                  </Route>
                </Routes>
                <GlobalStyles />
                <ToastContainer />
              </Layout>
            </Router>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </>
  );
}