import React from "react";
import {
  AuthorWrapper,
  BlogDetail,
  BlogDetailItems,
  BlogItembg,
  BlogText,
} from "./BlogList.styles";
import { Typography, Paper, Grid } from "@mui/material";

import { TopData } from "./BlogData";
import PersonIcon from "@mui/icons-material/Person";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
interface Top {
  title: string;
  data: Blog[];
  showDetails?: boolean;
}
interface Blog {
  img: string;
  blogHeading: string;
  postedby: string;
  time: string;
  views: string;
  discreption: string;
}
const TopAuthor: React.FC<Top> = ({ title, data, showDetails = true }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("tablet"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobiledisc = useMediaQuery(theme.breakpoints.up("xs"));
  return (
    <AuthorWrapper>
      <Typography variant="h1">{title}</Typography>
      <Paper sx={{ padding: "0 20px" }}>
        <Grid container spacing={2}>
          {data.map((elem, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Typography variant="h3" color={theme.palette.grey[200]}>
                Top Editor
              </Typography>
              <BlogItembg>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={3}>
                    <img
                      src={elem.img}
                      alt="item1"
                      className="profile"
                      style={{
                        [theme.breakpoints.down("sm")]: {
                          width: "116px",
                          height: "123px",
                        },
                      }}
                    />
                  </Grid>
                  <BlogText item xs={9}>
                    <Typography variant="h2">{elem.blogHeading}</Typography>
                    <BlogDetail>
                      <BlogDetailItems color="#17637B">
                        <PersonIcon
                          sx={{
                            color: theme.palette.background.default,
                            fontSize: "12px",
                          }}
                        />
                        <Typography variant="body1">{elem.postedby}</Typography>
                      </BlogDetailItems>
                      <BlogDetailItems color="#17637B">
                        <WatchLaterIcon
                          sx={{
                            color: theme.palette.background.default,
                            fontSize: "10px",
                          }}
                        />
                        <Typography variant="body1">{elem.time}</Typography>
                      </BlogDetailItems>
                      <BlogDetailItems color="">
                        <PeopleAltIcon
                          sx={{
                            color: theme.palette.background.default,
                            fontSize: "12px",
                          }}
                        />
                        <Typography variant="body1">{elem.views}</Typography>
                      </BlogDetailItems>
                    </BlogDetail>
                    <Typography variant="body1" style={{color: theme.palette.text.primary}}>
                      {desktop && elem.discreption}
                      {tablet && elem.discreption.slice(0, 349)}
                      {mobiledisc && elem.discreption.slice(0, 221)}
                    </Typography>
                  </BlogText>
                </Grid>
              </BlogItembg>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </AuthorWrapper>
  );
};

export default TopAuthor;
