import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
  Paper,
  Grid,
  Box,
  Avatar,
  CircularProgress,
} from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Country, State, City } from "country-state-city";
import { styled } from "@mui/material/styles";
import { Member, User } from "../../utils/types/userManagement";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { storage } from "../../redux/firebase";
import { modifyAuthUser } from "../../redux/auth/authService";

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(5),
  background: "#f5f7fa",
  borderRadius: 16,
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
}));

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  fontSize: "16px",
  fontWeight: "bold",
  background: "linear-gradient(45deg, #1976d2, #4fc3f7)",
  color: "#fff",
  "&:hover": {
    background: "linear-gradient(45deg, #1565c0, #29b6f6)",
  },
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(14),
  height: theme.spacing(14),
  marginBottom: theme.spacing(2),
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
}));

const FormSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "18px",
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));

const PhoneInputWrapper = styled(Box)(({ theme }) => ({
  "& .PhoneInput": {
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&:focus-within": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
    },
  },
  "& .PhoneInputInput": {
    border: "none",
    outline: "none",
    width: "100%",
    fontSize: "16px",
    padding: theme.spacing(1),
  },
}));

// Type guard to check if an object is of type `Member`
function isMember(user: Member | User | null): user is Member {
  return (user as Member)?.authid !== undefined;
}

export const ProfileEditPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user: currentUser } = useAppSelector((state) => state.auth);
  const [user, setUser] = useState<Member | null>(null);

  const [formData, setFormData] = useState<Partial<Member>>({
    name: "",
    tagline: "",
    churchBranch: "",
    city: "",
    country: "",
    state: "",
    address: "",
    prayerRequest: "",
    profilepic: "",
    phoneNumber: "",
  });
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [profilePicFile, setProfilePicFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (isMember(currentUser)) {
      setUser(currentUser);
      setFormData({
        name: currentUser.name || "",
        tagline: currentUser.tagline || "",
        churchBranch: currentUser.churchBranch || "",
        city: currentUser.city || "",
        country: currentUser.country || "",
        state: currentUser.state || "",
        address: currentUser.address || "",
        prayerRequest: currentUser.prayerRequest || "",
        profilepic: currentUser.profilepic || "",
        phoneNumber: currentUser.phoneNumber || "",
      });
      setSelectedCountry(currentUser.country || "");
      setSelectedState(currentUser.state || "");
      setSelectedCity(currentUser.city || "");
    }
  }, [currentUser]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setProfilePicFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setIsUploading(true);
      let profilePicUrl = formData.profilepic;
      if (profilePicFile) {
        const storageRef = ref(storage, `userprofilepics/${profilePicFile.name}`);
        await uploadBytes(storageRef, profilePicFile);
        profilePicUrl = await getDownloadURL(storageRef);
      }

      const updatedData: Partial<Member> = {
        ...formData,
        profilepic: profilePicUrl,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
      };

      const filteredData = Object.fromEntries(
        Object.entries(updatedData).filter(([_, value]) => value !== "")
      );

      if (user?.authid) {
        await dispatch(modifyAuthUser({ data: filteredData, id: user.authid })).unwrap();

        navigate(`/profile/${user.authid}`);
      } else {
        throw new Error("User ID not found");
      }
    } catch (error) {
      console.error("Failed to update profile:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
          <ProfileAvatar src={formData.profilepic || ""} alt="Profile Picture" />
          <Typography variant="h5" fontWeight="bold">
            {formData.name || "Edit Profile"}
          </Typography>
        </Box>
        <StyledForm onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormSectionTitle>Name</FormSectionTitle>
              <TextField
                fullWidth
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSectionTitle>Phone Number</FormSectionTitle>
              <PhoneInputWrapper>
                <PhoneInput
                  international
                  defaultCountry="CM"
                  value={formData.phoneNumber}
                  onChange={(value) =>
                    setFormData({
                      ...formData,
                      phoneNumber: value?.toString() || "",
                    })
                  }
                />
              </PhoneInputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSectionTitle>Country</FormSectionTitle>
              <FormControl fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value as string)}
                >
                  {Country.getAllCountries().map((country) => (
                    <MenuItem key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSectionTitle>State</FormSectionTitle>
              <FormControl fullWidth>
                <InputLabel>State</InputLabel>
                <Select
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value as string)}
                >
                  {State.getStatesOfCountry(selectedCountry).map((state) => (
                    <MenuItem key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSectionTitle>City</FormSectionTitle>
              <FormControl fullWidth>
                <InputLabel>City</InputLabel>
                <Select
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value as string)}
                >
                  {City.getCitiesOfState(selectedCountry, selectedState).map((city) => (
                    <MenuItem key={city.name} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSectionTitle>Church Branch</FormSectionTitle>
              <FormControl fullWidth>
                <Select
                  value={formData.churchBranch}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      churchBranch: e.target.value as string,
                    })
                  }
                >
                  {["Head Quarters", "Buea", "Dubai", "Muyuka", "Yaounde", "Other"].map(
                    (branch) => (
                      <MenuItem key={branch} value={branch}>
                        {branch}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormSectionTitle>Address</FormSectionTitle>
              <TextField
                fullWidth
                placeholder="Enter your address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSectionTitle>Tagline</FormSectionTitle>
              <TextField
                fullWidth
                placeholder="Enter a tagline"
                name="tagline"
                value={formData.tagline}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSectionTitle>Prayer Request</FormSectionTitle>
              <TextField
                fullWidth
                placeholder="Enter your prayer request"
                name="prayerRequest"
                value={formData.prayerRequest}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSectionTitle>Profile Picture</FormSectionTitle>
              <input type="file" onChange={handleFileChange} accept="image/*" />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                type="submit"
                variant="contained"
                fullWidth
                disabled={isUploading}
              >
                {isUploading ? <CircularProgress size={24} color="inherit" /> : "Update Profile"}
              </StyledButton>
            </Grid>
          </Grid>
        </StyledForm>
      </StyledPaper>
    </Container>
  );
};

export default ProfileEditPage;