
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { addFaq, deleteFaq, getFaqs, updateFaq } from "./questionsService";
import { FaqsState } from "../../../utils/types/faqManagement";

const initialState: FaqsState = {
  faqs: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

export const faqSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFaqs.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })

      .addCase(getFaqs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = null;
        state.faqs = action.payload || [];
      })
      .addCase(getFaqs.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to fetch posts!";
      })
      .addCase(addFaq.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(addFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Faq created successfully!";
        toast.success("Faq created successfully!");
        const newFaq = action.payload;

        state.faqs = [...state.faqs, newFaq.faq];
      })
      .addCase(addFaq.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to create faq!";
      })
      .addCase(updateFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Faq updated successfully!";
        toast.success("Faq updated successfully!");

        const updatedFaq = action.payload;
        const index = state.faqs.findIndex((faq) => faq.id === updatedFaq.id);
        if (index !== -1) {
          state.faqs[index] = updatedFaq;
        }
      })
      .addCase(updateFaq.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to update faq";
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Faq deleted successfully!";

        const deletedFaqId = action.payload?.id;

        if (deletedFaqId) {
          state.faqs = state.faqs.filter((faq) => faq.id !== deletedFaqId);
        }
      })
      .addCase(deleteFaq.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to delete faq";
      });
  },
});

export default faqSlice.reducer;
