import {
    DocumentSnapshot,
  } from "firebase/firestore";
  
  export interface EventModelProps {
    eventId: string;
    title: string;
    description: string;
    startTime: number;
    endTime: number;
    location: string;
    attendees: string[];
    isFeatured: boolean;
    imageUrl: string;
    tags: string[];
    organizerName: string;
    organizerEmail: string;
    organizerPhoneNumber: string;
    isRegistrationRequired: boolean;
    maximumAttendees: number;
    isCancelled: boolean;
  }
  export interface EventsState{
    events: EventModelProps[], 
    isLoading: boolean;
    isSuccess: boolean;
    hasError: boolean;
    hasWarning: boolean;
    error: string | null;
    successMessage: string | null;
    }
  export class EventModel {
    eventId: string;
    title: string;
    description: string;
    startTime: number;
    endTime: number;
    location: string;
    attendees: string[];
    isFeatured: boolean;
    imageUrl: string;
    tags: string[];
    organizerName: string;
    organizerEmail: string;
    organizerPhoneNumber: string;
    isRegistrationRequired: boolean;
    maximumAttendees: number;
    isCancelled: boolean;
  
    constructor(props: EventModelProps) {
      this.eventId = props.eventId;
      this.title = props.title;
      this.description = props.description;
      this.startTime = props.startTime;
      this.endTime = props.endTime;
      this.location = props.location;
      this.attendees = props.attendees;
      this.isFeatured = props.isFeatured;
      this.imageUrl = props.imageUrl;
      this.tags = props.tags;
      this.organizerName = props.organizerName;
      this.organizerEmail = props.organizerEmail;
      this.organizerPhoneNumber = props.organizerPhoneNumber;
      this.isRegistrationRequired = props.isRegistrationRequired;
      this.maximumAttendees = props.maximumAttendees;
      this.isCancelled = props.isCancelled;
    }
  
    // Convert EventModel instance to Firestore-compatible object
    toMap(): Record<string, any> {
      return {
        eventId: this.eventId,
        title: this.title,
        description: this.description,
        startTime: this.startTime,
        endTime: this.endTime,
        location: this.location,
        attendees: this.attendees,
        isFeatured: this.isFeatured,
        imageUrl: this.imageUrl,
        tags: this.tags,
        organizerName: this.organizerName,
        organizerEmail: this.organizerEmail,
        organizerPhoneNumber: this.organizerPhoneNumber,
        isRegistrationRequired: this.isRegistrationRequired,
        maximumAttendees: this.maximumAttendees,
        isCancelled: this.isCancelled,
        createdate: new Date().getTime(), // Add creation date
      };
    }
  
    // Static method to create an EventModel instance from Firestore data
    static fromMap(snapshot: DocumentSnapshot): EventModel {
      const data = snapshot.data();
  
      if (!data) {
        throw new Error("No data found in the snapshot");
      }
  
      return new EventModel({
        eventId: data.eventId || "",
        title: data.title || "",
        description: data.description || "",
        startTime: data.startTime,
        endTime:data.endTime,
        location: data.location || "",
        attendees: (data.attendees as string[]) || [],
        isFeatured: data.isFeatured || false,
        imageUrl: data.imageUrl || "",
        tags: (data.tags as string[]) || [],
        organizerName: data.organizerName || "",
        organizerEmail: data.organizerEmail || "",
        organizerPhoneNumber: data.organizerPhoneNumber || "",
        isRegistrationRequired: data.isRegistrationRequired || false,
        maximumAttendees: data.maximumAttendees || 0,
        isCancelled: data.isCancelled || false,
      });
    }
  }