

import { Background } from "../components/HomeContainer.style";
import HomePage from "../components/tec/home/HomePage";

export default function Main() {

  return (
    <>
     <Background>
      <HomePage />
     </Background>
  
    </>
  );
}