import React from 'react'
import LogPage from '../components/login/LoginPage';
import img1 from "../assets/blog/blog2.png";
import { Background } from '../components/HomeContainer.style';
export const login = () => {
  return (
    <Background>
      <LogPage/>
    </Background>
  )
}


