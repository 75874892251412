import { createAsyncThunk } from "@reduxjs/toolkit";
import { User, Member } from "../../utils/types/userManagement"; // Import both User and Member
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { getAuth, updateEmail } from "firebase/auth";

/**
 * Get all users (regular users from Firebase Auth and members from Firestore)
 */
export const getUsers = createAsyncThunk<
  Member[] | null,
  void,
  { rejectValue: string }
>("users/getUsers", async (_, { rejectWithValue }) => {
  try {
    // Fetch members from Firestore
    const membersCollection = collection(db, "members");
    const membersSnapshot = await getDocs(membersCollection);
    const members: Member[] = membersSnapshot.docs.map((doc) => ({
      ...doc.data(),
      authid: doc.id,
      lastseen: null,
    })) as Member[];

    return members;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
/**
 * Modify a user (regular or member) by ID
 */
export const modifyUser = createAsyncThunk<
  Member,
  { data: Partial<Member>; id: string },
  { rejectValue: string }
>("users/modifyUser", async ({ data, id }, { rejectWithValue }) => {
  try {
    const memberRef = doc(db, "members", id);
    await updateDoc(memberRef, data);

    if (data.email && auth.currentUser) {
      await updateEmail(auth.currentUser, data.email);
    }

    const updatedMember = await getDoc(memberRef);
    if (updatedMember.exists()) {
      return updatedMember.data() as Member;
    } else {
      return rejectWithValue("Member not found");
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

/**
 * Delete a user (regular or member) by ID
 */
export const deleteUser = createAsyncThunk<
  { message: string; id: string },
  { id: string },
  { rejectValue: string }
>("users/deleteUser", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteDoc(doc(db, "members", id));
    return { message: "Member successfully deleted", id };
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
/**
 * Update user partnerships (for members only)
 */
export const updatePartnership = createAsyncThunk<
  void,
  { partnershipName: string },
  { rejectValue: string }
>(
  "users/updatePartnership",
  async ({ partnershipName }, { rejectWithValue }) => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const memberRef = doc(db, "members", currentUser.uid);
        const memberDoc = await getDoc(memberRef);

        if (memberDoc.exists()) {
          const memberData = memberDoc.data() as Member;
          const mypartnerships = memberData.mypartnerships || [];
          const updatedPartnerships = mypartnerships.includes(partnershipName)
            ? mypartnerships.filter((p) => p !== partnershipName)
            : [...mypartnerships, partnershipName];

          await updateDoc(memberRef, { mypartnerships: updatedPartnerships });
        } else {
          return rejectWithValue("User is not a member");
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
