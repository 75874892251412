// src/services/apiService.ts
import axios from 'axios';
import { toast } from 'react-toastify';

const API_KEY = 'AIzaSyDHKqt4MRHq0oPf5d9PhSAm0VOca8GN9Cg';
const BASE_URL = 'https://www.googleapis.com/youtube/v3';

const apiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
export const likeVideo = async (videoId: string) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/videos/rate`,
        null,
        {
          params: {
            id: videoId,
            rating: 'like',
            key: API_KEY,
          },
        }
      );
      console.log('Video liked successfully:', response.data);
    } catch (error) {
      console.error('Error liking video:', error);
    }
  };

  export const subscribeToChannel = async (channelId: string) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/subscriptions?part=snippet`,
        {
          snippet: {
            resourceId: {
              kind: 'youtube#channel',
              channelId,
            },
          },
        },
        {
          params: {
            key: API_KEY,
          },
        }
      );
      toast('Subscribed successfully:');
      console.log('Subscribed successfully:', response.data);
    } catch (error) {
        toast('Error subscribing to channel');
      console.error('Error subscribing to channel:', error);
    }
  };
export { apiService, API_KEY };