import { CheckCircleOutline, DownloadingSharp } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardMedia, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { FaCalendar } from "react-icons/fa";

type LeadershipMember ={
    name: string;
    role : string;
    photo : string;
    bio: string;
    contact : string;
}


type Events = {
title : string;
date : string;
description: string
}

type Resource = {
    name : string;
    url : string;

}

type Testimony = {
    name : string;
    message : string;
};

type Ministry ={
    name : string;
    heroImage : string;
    overview : string;
    mission : string;
    leadership : LeadershipMember[];
    goals : string[];
    event: Events[];
    resources :Resource[];
    testimonies :Testimony[]
}

interface MinstryProps {
    ministry : Ministry;
}

const MinistryPage : React.FC<MinstryProps>=({ministry}) =>{
    return(
        <Box>
            <Box sx={{
                position:'relative',backgroundImage:`url(${ministry.heroImage})`,backgroundSize:'cover',backgroundPosition:'center',
                height:'400px',color:'white',display:'flex',alignItems:'center',justifyContent:'center',textAlign:'center'
            }}>
<Box sx={{
    position:"absolute",top:0,left:0,width:'100%',height:'100%',background:'rgba(0,0,0,0.5)'
}}
/>
<Box zIndex={1}>
<Typography variant="h1">{ministry.name}</Typography>
<Typography variant="h6">{ministry.mission}</Typography>


</Box>
            </Box>
            <Box sx={{p:4, textAlign:'center'}}>
                <Typography variant="h4" color="primary " gutterBottom>About Us</Typography>
                <Typography variant="body1" color="textSecondary">{ministry.overview}</Typography>
            </Box>
            <Box sx={{p:4}}>
                <Typography variant="h4" color="primary" gutterBottom>Leadership</Typography>
                <Grid container spacing={4}>{ministry.leadership.map((leader,index)=>(
                    <Grid item xs={12} sm={6} md={4} key={index}>
<Card sx={{boxShadow:3}}>
    <CardMedia component='img' height="200" image={leader.photo} />
    <CardContent >
        <Typography variant="h6">{leader.name}</Typography>
        <Typography variant="body2" color="textSecondary">{leader.role}</Typography>
        <Typography variant="body2" mt={1}>{leader.bio}</Typography>
        <Button variant="text" color="secondary" href={`mailto :${leader.contact}`}>Contact</Button>
    </CardContent>
</Card>


                    </Grid> ))}</Grid>
            </Box>
<Box sx={{p:4, backgroundColor:'#f9f9f9'}}>
    <Typography variant="h4" color="primary" gutterBottom>Goals</Typography>
    <List>
        {ministry.goals.map((goal,index)=>(
            <ListItem key={index}>
                <ListItemIcon><CheckCircleOutline color="primary" /></ListItemIcon>
                <ListItemText primary={goal} />
            </ListItem>
        ))}
    </List>
</Box>
    <Box sx={{P : 4}} >
        <Typography variant="h4" color="primary" gutterBottom>
            Upcoming Events
        </Typography>
        <Grid container spacing={4} >
{ministry.event.map((events,index)=>(
    <Grid item xs={12} sm={6} md={4} key={index}>
<Card sx={{boxShadow:3}}>
    <CardContent>
    <Typography variant="h6" color="secondary" >
            {events.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" >
           <FaCalendar fontSize='small' />{events.date}
        </Typography>
        <Typography variant="body2" mt={1}>
          {events.description}
        </Typography>
    </CardContent>
</Card>

    </Grid>
))}

        </Grid>
        </Box>  
       
       <Box
        sx={{p:4,backgroundColor:'#f9f9f9'}} >
            <Typography variant="h4" color="primary" gutterBottom>
           Testiomonies
        </Typography>
        <Grid container spacing={4} >
{ministry.testimonies.map((testimony,index)=>(
    <Grid item xs={12} sm={6} md={4} key={index}>
<Paper elevation={3} sx={{p:2}}>
    <Typography variant="body1" color="textSecondary">"{testimony.message}"   </Typography>
    <Typography variant="subtitle2" mt={1} >{testimony.name}</Typography>
</Paper>


    </Grid>
))}

        </Grid>
        </Box>
        <Box sx={{p:4, backgroundColor:'#f9f9f9'}}>
    <Typography variant="h4" color="primary" gutterBottom>Resources</Typography>
    <List>
        {ministry.resources.map((resource,index)=>(
            <ListItem key={index}>
                <ListItemIcon><DownloadingSharp color="secondary" /></ListItemIcon>
                <ListItemText primary={resource.name}  
                secondary={
                    <Button variant="outlined" color="secondary" href={resource.url}>Download</Button>
                }
                />
                
            </ListItem>
        ))}
    </List>
</Box>
       
       
        </Box>
    )
}
export default MinistryPage;