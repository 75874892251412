import { useEffect, useState } from "react";

const LoadingScreen = () => {
  const colors = ["FF524C", "E09B3D", "05BCF5", "1573AE"];
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [dotIndex, setDotIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 500);

    return () => clearInterval(interval);
  }, [currentColorIndex, colors.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 500);

    return () => clearInterval(interval);
  }, [dotIndex]);

  const circleStyles = {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    margin: "5px",
    animation: "pulse 1s infinite alternate",
  };

  const trainStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const textStyles = {
    marginTop: "10px",
    fontFamily: "Arial, sans-serif",
    fontSize: "18px",
    fontWeight: "bold",
  };

  const dots = [".", ".", ".", "."];
  const dotsToShow = dots.slice(0, dotIndex + 1).join("");

  const circles = colors.map((color, index) => (
    <div
      key={index}
      style={{
        ...circleStyles,
        backgroundColor: `${color}`,
        animationDelay: `${index * 0.2}s`,
        visibility: index <= currentColorIndex ? "visible" : "hidden",
      }}
    />
  ));

  return (
    <>
      <div style={trainStyles}>{circles}</div>
      <div style={textStyles}>Loading{dotsToShow}</div>
    </>
  );
};

export default LoadingScreen;
