// src/components/LiveVideos.tsx
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getLiveVideos } from "../../redux/youtubemanager/youtubeSliece";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  DialogContent,
  Dialog,
  Button,
} from "@mui/material";
import { likeVideo } from "../../utils/youtubeApi";


const LiveVideos: React.FC<{ channelId: string }> = ({ channelId }) => {
  const dispatch = useAppDispatch();
  const { liveVideos, loading, error } = useAppSelector(
    (state) => state.youtube
  );
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  // Handle video selection
  const handleVideoClick = (videoId: string) => {
    setSelectedVideo(videoId);
  };

  // Handle closing the video player
  const handleCloseVideo = () => {
    setSelectedVideo(null);
  };

  useEffect(() => {
    dispatch(getLiveVideos(channelId));
  }, [dispatch, channelId]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ padding: 2 }}>
        {liveVideos.length === 0 ? (
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "30px",
            }}
            variant="h3"
            sx={{ marginBottom: 2 }}
          >
            No Live Videos available
          </Typography>
        ) : (
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "30px",
            }}
            variant="h3"
            sx={{ marginBottom: 2 }}
          >
            Live Videos:
          </Typography>
        )}

        <Grid container spacing={2}>
          {liveVideos.map((video) => (
            <Grid item xs={12} sm={6} md={4} key={video.id}>
              <Card
                onClick={() => handleVideoClick(video.id)}
                sx={{ cursor: "pointer" }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={video.thumbnailUrl}
                  alt={video.title}
                />
                <CardContent>
                  <Typography variant="h6" noWrap>
                    {video.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Video Player Dialog */}
        <Dialog
          open={!!selectedVideo}
          onClose={handleCloseVideo}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            {selectedVideo && (
              <Box sx={{ position: "relative", paddingTop: "56.25%" }}>
                <iframe
                  title="YouTube Video Player"
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${selectedVideo}`}
                  // frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => likeVideo(selectedVideo as string)}
              >
                Like
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCloseVideo}
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default LiveVideos;
