import { createSlice } from "@reduxjs/toolkit";
import { newsLetter } from "./newsLetterService";
type Props = {
  email: string;
  isLoading: boolean;
  isSuccess: boolean;
  hasError: boolean;
  hasWarning: boolean;
  error: string | null;
  successMessage: {
    message?: string;
  };
};
const initialState: Props = {
  email: "",
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: "",
  successMessage: {
    message: "",
  },
};

export const newsLetterSubscription = createSlice({
  name: "newsLetterSubscription",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.hasError = false;
      state.isSuccess = false;
      state.hasWarning = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newsLetter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.hasWarning = false;
        state.hasError = false;
        state.isSuccess = false;
      })
      .addCase(newsLetter.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.user = action.payload;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = action.payload;
      })
      .addCase(newsLetter.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        state.error = action.payload as unknown as string;
      });
  },
});

export const { refreshState } = newsLetterSubscription.actions;
export default newsLetterSubscription.reducer;
