import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  styled,

} from "@mui/material";
import { motion } from "framer-motion";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import img1 from "../../../../assets/images/tecglobal.jpg";
import img2 from "../../../../assets/images/churchbg.jpg";
import img3 from "../../../../assets/images/empfoundation.jpg";
import img4 from "../../../../assets/images/etv.jpg";
import img5 from "../../../../assets/images/12.jpg";
import img6 from "../../../../assets/images/school.png";
import { Link, useNavigate } from "react-router-dom";
// Styled Components
const HeroTwoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "5vh",
  background: `linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ), url(${img1})`,
  backgroundAttachment: "fixed",
  minHeight: "86vh",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  borderRadius: "20px",
  overflow: "hidden",
  padding: "3%",
  [theme.breakpoints.down("md")]: {
    minHeight: "90vh",
    backgroundPosition: "center",
    marginTop:"10vh",
  },
}));

const HeroTwoContainerOverlay = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  borderRadius: "20px",
  padding: "3%",
  boxShadow: "0px 8px 25px rgba(0,0,0,0.3)",
}));

const StyledCard = styled(motion(Card))(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  overflow: "hidden",
  justifyItems: "center",
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.5)",
  border: "2px solid transparent",
  backgroundClip: "padding-box",
  transition: "transform 0.4s ease, box-shadow 0.4s ease",
  background: "rgba(255, 255, 255, 0.2)",
  "&:hover": {
    transform: "scale(1.08) rotate(1deg)",
    boxShadow: "0px 15px 40px rgba(0, 0, 0, 0.6)",
    border: "2px solid rgba(255, 255, 255, 0.4)",
  },
}));

const CardOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0.2))",
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: "20px",
}));

const CardText = styled(Typography)(({ theme }) => ({
  color: "white",
  fontWeight: 500,
  lineHeight: 1.6,
}));

const CardButton = styled(Button)(({ theme }) => ({
  marginTop: "10px",
  color: "white",
  backgroundColor: theme.palette.primary.main,
  fontWeight: "bold",
  borderRadius: "30px",
  textTransform: "none",
  padding: "10px 20px",
  fontSize: "1rem",
  transition: "all 0.3s ease",
  alignSelf: "center",
  display: "flex", // Add display: flex
  alignItems: "center", // Align items vertically within the button
  justifyContent: "center", // Center horizontally (optional)
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: "black",
    transform: "scale(1.1)",
    boxShadow: "0px 8px 20px rgba(255, 255, 255, 0.5)",
  },
}));
const fadeIn = {
  hidden: { opacity: 0, translateY: 50 },
  visible: { opacity: 1, translateY: 0, transition: { duration: 0.6 } },
};

const cardData = [
    {
      title: "The Empowerment Church International",
      description:
        "An arena of inextinguishable grace training Kingdom Militants for Ministry and Purpose in alignment to God's will.",
      img: img2,
      link: "/church",
    },
    {
      title: "The Empowerment Foundation",
      description:
        "An all-in-one global interaction milieu to spread God's everlasting kindness to mankind.",
      img: img3,
      link: "https://foundation.empowermentrevolution.org",
    },

    {
      title: "Empowerment TV",
      description: "Transformin Nations, Giving Meaning to Lives",
      img: img4,
      link: "/tv",
    },
    {
      title: "TEC GLOBAL",
      description:
        "An all-in-one application reconnecting Christians from around the globe.",
      img: img1,
      link: "/download",
    },
    {
      title: "The Empowerment Bilingual Nursery and Primary School",
      description:
        "A milieu to train our kids to become future leaders of tomorrow.",
      img: img6,
      link:"https://school.empowermentrevolution.org",
    },
    {
      title: "Jesus Christ Media",
      description: "Prophet Aldrian Inspirational, access bible, messages ",
      img: img5,
      link: "#",
    },
  ];


export default function HeroTwo() {

  return (
    <HeroTwoContainer>
      <HeroTwoContainerOverlay>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            marginBottom: "20px",
            color: "black",
            textAlign: "center",
            fontFamily: "serif",
          }}
        >
          Arms of the Revolution
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "20px",
            width: "100%",
            marginTop: "30px",
          }}
        >
          {cardData.map((item, index) => (
            <StyledCard
              key={index}
              whileHover={{ scale: 1.05 }}
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              <CardMedia
                component="img"
                height="300"
                image={item.img}
                alt={item.title} 
              />
              <CardOverlay>
                <Typography
                  variant="h5"
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    textAlign: "center",
                    textShadow: "0px 4px 10px rgba(0,0,0,0.8)",
                  }}
                >
                  {item.title}
                </Typography>
                <CardText variant="body2" textAlign={"center"}>
                  {item.description}
                </CardText>
                <Link to={`${item.link}`} target={item.link.includes("https")?'_blank':"_parent"}>
                  <center>
                    {" "}
                    <CardButton
                      style={{ alignItems: "center" }}
                      endIcon={<ArrowForwardIcon />} 
                    >
                      Learn More
                    </CardButton>
                  </center>{" "}
                </Link>
              </CardOverlay>
            </StyledCard>
          ))}
        </Box>
      </HeroTwoContainerOverlay>
    </HeroTwoContainer>
  );
}
