import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl as baseUrl } from "../../utils/baseUrl";
import { AddContact, Contact } from "../../utils/types/contacts";

export const getContacts = createAsyncThunk<
  Contact[] | null,
  string,
  { rejectValue: string[] }
>("contacts", async (token, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${baseUrl()}/v1/aproplat/contact_messages`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Failed to fetch contact", error);
    return rejectWithValue(["Failed to fetch data"]);
  }
});

export const addContact = createAsyncThunk<
  Contact,
  { data: AddContact; token: string },
  { rejectValue: string[] }
>("addContact", async ({ data, token }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${baseUrl()}/v1/aproplat/contact_messages`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Failed to create contact", error);
    return rejectWithValue(["Failed to create contact"]);
  }
});

export const updateContact = createAsyncThunk<
  Contact,
  { data: AddContact; token: string; id: number },
  { rejectValue: string[] }
>("updateContact", async ({ data, token, id }, { rejectWithValue }) => {
  try {
    const response = await axios.patch(
      `${baseUrl()}/v1/aproplat/contact_messages/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Failed to update contact", error);
    return rejectWithValue(["Failed to update contact"]);
  }
});

export const deleteContact = createAsyncThunk<
  { message: string; id: number },
  { id: number; token: string },
  { rejectValue: string[] }
>("deleteContact", async ({ id, token }, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      `${baseUrl()}/v1/aproplat/contact_messages/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { message: response.data, id };
  } catch (error) {
    console.log("Failed to delete contact", error);
    return rejectWithValue(["Failed to delete contact"]);
  }
});
