import { ThemeProvider } from "styled-components"
import { useTheme } from "styled-components"
import MinistryPage from "./departments/ministryComponent"
import theme from "../../styles/theme";
import img1 from '../../assets/images/7.jpg';
import img2 from '../../assets/images/5.jpg';
export default function WomenMinistry(){
   
    return(
        <ThemeProvider  theme={theme} >
          {/* Women's Ministry Page */}
        <MinistryPage
          ministry={{
            name: "Women's Ministry",
            heroImage:
             img1,
            overview:
              "Our Women's Ministry empowers women to grow in their faith, build meaningful relationships, and serve others.",
            mission:
              "To inspire and equip women to fulfill their God-given purpose.",
            leadership: [
              {
                name: "Emily Davis",
                role: "Women's Pastor",
                photo: img2,
                bio: "Emily is passionate about helping women discover their identity in Christ and live boldly.",
                contact: "emily.davis@example.com",
              },
            ],
            goals: [
              "Encourage women to deepen their faith and prayer life.",
              "Foster meaningful relationships and support networks.",
              "Inspire women to serve in their families, church, and community.",
            ],
            event: [
              {
                title: "Women's Conference",
                date: "May 8, 2024",
                description:
                  "A day of worship, teaching, and encouragement for women.",
              },
              {
                title: "Ladies' Night Out",
                date: "August 12, 2024",
                description:
                  "An evening of fun, food, and fellowship for women of all ages.",
              },
            ],
            resources: [
              { name: "Women's Prayer Journal", url: "#" },
              { name: "Bible Study Workbook", url: "#" },
            ],
            testimonies: [
              {
                name: "Sarah Williams",
                message:
                  "The Women's Ministry has been life-changing. The friendships and encouragement here are priceless!",
              },
            ],
          }}
        />
       </ThemeProvider>
    )
}