import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import MenuIcon from "@mui/icons-material/Menu";
import {
  FaHome,
  FaInfoCircle,
  FaPhoneSquare,
  FaChurch,
  FaTv,
  FaNewspaper,
} from "react-icons/fa";
import logo from "../../../assets/images/tec.png";
import mobilelogo from "../../../assets/images/tec.png";
import { useAppSelector } from "../../../redux/store";
import UserDropdown from "./userDropDown";

const NavbarContainer = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  position: "sticky",
  top: 0,
  zIndex: 1000,
}));

const NavbarLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
  fontSize: "16px",
  fontWeight: 500,
  padding: "10px 15px",
  borderRadius: "5px",
  transition: "all 0.3s ease-in-out",
  display: "flex",
  alignItems: "center",
  gap: "8px",

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.main,
  },
}));

const DropdownMenuContainer = styled(Box)(() => ({
  position: "relative",
  "&:hover .dropdown-menu": {
    display: "block",
  },
}));

const DropdownMenuContent = styled(Box)(({ theme }) => ({
  display: "none",
  position: "absolute",
  top: "100%",
  left: 0,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  zIndex: 10,
  borderRadius: "5px",
  minWidth: "150px",
  overflow: "hidden",

  "& a": {
    color: theme.palette.text.primary,
    textDecoration: "none",
    padding: "10px 15px",
    display: "block",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
    },
  },
}));

const Navbar: React.FC<any> = ({
  toggle,
  setToggle,
  toggleTheme,
  setToggleTheme,
  light,
  setLight,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery(theme.breakpoints.down("tablet"));

  // Use the Redux selector to get the authentication state
  const user = useAppSelector((state) => state.auth.user);

  const handleThemeToggle = () => {
    setLight(!light);
    setToggleTheme(!toggleTheme);
  };

  return (
    <NavbarContainer>
      <Toolbar sx={{ justifyContent: "space-between", padding: "0 20px" }}>
        {/* BRAND LOGO */}
        <Box>
          <Link to="/">
            <img
              src={isMobile ? mobilelogo : logo}
              alt="Tec Global"
              style={{
                height: isMobile ? "7vh" : "7vh",
                width: isMobile ? "70%" : "auto",
              }}
            />
          </Link>
        </Box>

        {/* NAVIGATION LINKS */}
        {!isMobile && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <NavbarLink to="/">
              <FaHome /> Home
            </NavbarLink>

            {/* CHURCH DROPDOWN */}
            <DropdownMenuContainer>
              <NavbarLink to="#">
                <FaChurch /> Church
              </NavbarLink>
              <DropdownMenuContent className="dropdown-menu">
                <NavbarLink to="/church">
                  <FaHome /> Home
                </NavbarLink>
                <NavbarLink to="/church/ministries">Ministries</NavbarLink>
                <NavbarLink to="/church/event">Events</NavbarLink>
                <NavbarLink to="/church/departments">Departments</NavbarLink>
                <NavbarLink to="/church/partnership">Partnership</NavbarLink>
                <NavbarLink to="/church/testimonies">Testimonies</NavbarLink>
                <NavbarLink to="/church/give">Give</NavbarLink>
                <NavbarLink to="/church/about">About</NavbarLink>
              </DropdownMenuContent>
            </DropdownMenuContainer>

            {/* TV DROPDOWN */}
            <DropdownMenuContainer>
              <NavbarLink to="#">
                <FaTv /> TV
              </NavbarLink>

              <DropdownMenuContent className="dropdown-menu">
                <NavbarLink to="/tv">
                  <FaHome /> Home
                </NavbarLink>
                <NavbarLink to="/tv/programs">Programs</NavbarLink>
                <NavbarLink to="/tv/workers">Workers</NavbarLink>
                <NavbarLink to="/tv/about">About</NavbarLink>
              </DropdownMenuContent>
            </DropdownMenuContainer>

            <NavbarLink to="/about">
              <FaInfoCircle /> About
            </NavbarLink>
            <NavbarLink to="/blog">
              <FaNewspaper /> Blog
            </NavbarLink>
            <NavbarLink to="/contact">
              <FaPhoneSquare /> Contact
            </NavbarLink>
          </Box>
        )}

        {/* AUTHENTICATION BUTTONS */}
        {!user ? (
          <Link to="/login">
            <Button
              sx={{
                background: theme.palette.background.default,
                border: "none",
                "&:hover": { background: "#FF0000" },
                width: "89px",
              }}
            >
              Login
            </Button>
          </Link>
        ) : (
          <UserDropdown /> // Show user dropdown if authenticated
        )}

        {/* RIGHT ACTION BUTTONS */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Button
            onClick={handleThemeToggle}
            sx={{
              background: theme.palette.primary.main,
              color: theme.palette.common.white,
              borderRadius: "20px",
              textTransform: "none",
              "&:hover": {
                background: theme.palette.primary.dark,
              },
            }}
          >
            {light ? <LightModeIcon /> : <DarkModeIcon />}
          </Button>
          {matches && (
            <IconButton onClick={() => setToggle(!toggle)}>
              <MenuIcon />
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </NavbarContainer>
  );
};

export default Navbar;