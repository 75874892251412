import { FormControlLabel, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { LoginData } from "../../utils/types";

import img1 from "../../assets/images/tec.png";
import {
  Buttons,
  ColorForm,
  FieldIconContainer,
  ForgetContainer,
  HeroLogin,
  InputChange,
  MyCheckBox,
  MyPasswordField,
  Progress,
  TextGridContainer,
  UserField,
} from "./LoginPage.style";
import { login } from "../../redux/auth/authService";
import { refreshState, updatePassword } from "../../redux/auth/authSlice";
import { Member, User } from "../../utils/types/userManagement";
import { getAuth } from "firebase/auth";

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isSuccess, hasError, error, isLoading, authenticated, user } = useAppSelector((state) => state.auth);
  const [isSubmitting, setSubmitting] = useState(false);

  // Handle navigation based on user role
  const isMember = (user: User | Member): user is Member => {
    return (user as Member).role !== undefined;
  };
  
  // Usage
  if (authenticated && user) { 
    if (isMember(user)) {
      const auth = getAuth();
      // Handle regular User case (no role)
      navigate(`/profile/${auth.currentUser?.uid}`);

    } else {
      const auth = getAuth();
      // Handle regular User case (no role)
      navigate(`/profile/${auth.currentUser?.uid}`);
    }
  }

  // Handle login submission
  const loginUser = async (values: LoginData) => {
    setSubmitting(true);
    dispatch(login(values));
    dispatch(updatePassword(values.password));
  };

  // Handle toast notifications and state reset
  useEffect(() => {
    if (!isLoading && isSubmitting) {
      if (hasError && error) {
        toast.error(error);
      } else if (isSuccess) {
        toast.success("Logged in successfully");
        dispatch(refreshState());
      }
      setSubmitting(false);
    }
  }, [dispatch, error, hasError, isLoading, isSubmitting, isSuccess]);

  return (
    <HeroLogin>
      <img src={img1} width={"10%"} height={"10%"} alt="" style={{ margin: "10px auto 30px" }} />
      <ColorForm>
        <Typography color="#FF0000" variant="h6">Login User</Typography>
        <Formik
          initialValues={{
            user_info: "",
            password: "",
            stayLogged: false,
          }}
          onSubmit={loginUser}
          validationSchema={Yup.object({
            user_info: Yup.string().required("Required"),
            password: Yup.string().required("Required"),
          })}
        >
          <Form>
            <InputChange>
              <FieldIconContainer />
              <UserField
                name="user_info" // Updated to match LoginData interface
                label=""
                placeholder="Email or Username"
              />
            </InputChange>

            <InputChange>
              <FieldIconContainer />
              <MyPasswordField
                name="password"
                placeholder="Password"
                label=""
              />
            </InputChange>

            <TextGridContainer container>
              <Grid item xs={5}>
                <FormControlLabel
                  control={<MyCheckBox name="stayLogged" label="Stay logged in" />}
                  label="Stay logged in"
                />
              </Grid>
              <Grid item xs={7}>
                <ForgetContainer>
                  <Link to="/passwordReset">
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "15px",
                        lineHeight: "18.29px",
                        color: "#1675B7",
                        cursor: "pointer",
                      }}
                    >
                      Forgot Password?
                    </Typography>
                  </Link>
                </ForgetContainer>
              </Grid>
            </TextGridContainer>

            <Buttons
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting || isLoading}
            >
              {isSubmitting || isLoading ? <Progress size={24} /> : "Login"}
            </Buttons>
            <Link to="/register">
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "15px",
                        lineHeight: "18.29px",
                        color: "#1675B7",
                        cursor: "pointer",
                      }}
                    >
                      Create Account?
                    </Typography>
                  </Link>
          </Form>
        </Formik>
      </ColorForm>
    </HeroLogin>
  );
};

export default LoginPage;