import { Box, Card, styled, Typography } from "@mui/material";
import img1 from '../../../assets/images/churchbg.jpg'
import img2 from '../../../assets/images/coll.jpeg'
export const AboutContainer = styled(Box)(({theme})=>({
    display:'flex',
    flexDirection:'column',
}))
export const ChurchAboutHeroContainer = styled(Box)(({theme})=>({
    display:'flex',
    flexDirection:'column',

 borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
   backgroundImage:`url(${img1})`,
    minHeight: '50vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', // Adjust background size for better responsiveness
    backgroundPositionY: 'center',
    backgroundPositionX: 'right',
    width: '100%',
    minWidth: '70%',
    maxWidth: '100%',

    [theme.breakpoints.down('md')]: {
        overflow: 'hidden',marginBottom:theme.spacing(4),
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '40vh', // Adjust height for smaller screens
        maxHeight: '50vh',
        height: '50vh',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center' // Center the background image on smaller screens
    }
}));

export const ChurchAboutHeroContainerOverlay = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: 'rgba(0,0,139,0.5)',
    minHeight: '50vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'right',

    [theme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '40vh', // Adjust height for smaller screens
        maxHeight: '50vh',
        height: '50vh',paddingTop:'20%',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center' // Center the background image on smaller screens
    }
}));

export const ChurchAboutHeroTitle = styled(Typography)(({ theme }) => ({
    fontSize: '3rem',
    fontWeight: 'bold',
    color: 'white',
    marginTop: '7%',
    textAlign: 'center',
    marginBottom: '0%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',width:'80%'
    }
}));
export const ChurchAboutHeroText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '400',
    color: 'white',
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '1%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    }
}));

export const AboutRow = styled(Box)(({theme})=>({
    display:'flex',margin:'4rem 6rem',
    flexDirection:'row',width:'100%',minWidth:'50%',maxWidth:'70%', justifyContent:'center',alignContent:'center',alignSelf:'center',alignItems:'center',
    [theme.breakpoints.down('md')]: {
        margin:'auto',minWidth:'50%',maxWidth:'90%',
        flexDirection:'column'
    },
}))
export const AboutCol = styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column',minWidth: '30%',
    borderRadius:'10px',
    maxWidth: '70%',
    width: '90%',
    [theme.breakpoints.down('md')]: {
        margin:'auto',minWidth:'50%',maxWidth:'100%',width:'100%'
        
    },
}))

export const AboutImage = styled(Box)(({theme})=>({
    marginLeft: '0%',
    
    height: '45vh',
    backgroundSize: 'cover', // Ensure image scales proportionally
    backgroundRepeat: 'no-repeat',
    minWidth: '30%',
    borderRadius:'10px',
    maxWidth: '50%',
    width: '90%',backgroundImage:`url(${img2})`,
    [theme.breakpoints.down('md')]: {
        height: '45vh', width:'100%',borderRadius:'10px',margin:'2% auto',minWidth:'50%',maxWidth:'90%'// Adjust height for smaller screens
    },
}))
export const AboutTitle = styled(Box)(({theme})=>({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '0%',fontFamily:'serif',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}))

export const AboutText = styled(Box)(({theme})=>({
    fontSize: '1rem',
    fontWeight: '400',
    color: 'black',
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '1%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    }
}))


