import EventsList from "./upcomingEventlist";
import {
  UpcomingEventsContainer,
  UpcomingEventsText,
  UpcomingEventsTitle,
} from "./upcomingEvents.styles";

export default function UpcomingEvents() {
  return (
    <div>
      <UpcomingEventsContainer>
        <UpcomingEventsTitle>Upcoming Events</UpcomingEventsTitle>
        <UpcomingEventsText>
          Empowerment Revolution is proud to present a series of upcoming events
          that ignite your passion,challenge your perspectives,and foster
          meaningful connections.From spiritual retreats to community service
          projects, our events promise to be unforgettable experiences. Mark
          your calendars and join us for life-changing moments ahead
        </UpcomingEventsText>
        <EventsList type="upcomming"/>
      </UpcomingEventsContainer>
    </div>
  );
}
