import { ThemeProvider } from "styled-components"
import { useTheme } from "styled-components"
import MinistryPage from "./departments/ministryComponent"
import theme from "../../styles/theme";
import img1 from '../../assets/images/7.jpg';
import img2 from '../../assets/images/5.jpg';
export default function YouthMinistry(){
   
    return(
        <ThemeProvider theme={theme} >
             {/* Youth Ministry Page */}
        <MinistryPage
          ministry={{
            name: "Youth Ministry",
            heroImage:
             img1,
            overview:
              "Our Youth Ministry is dedicated to inspiring and empowering young people to live for Christ and make an impact in their communities.",
            mission: "Building a generation of young leaders grounded in faith.",
            leadership: [
              {
                name: "John Doe",
                role: "Youth Pastor",
                photo: img2,
                bio: "John has been leading the youth ministry for 10 years, with a passion for mentoring young people.",
                contact: "john.doe@example.com",
              },
            ],
            goals: [
              "Foster spiritual growth among youth.",
              "Organize engaging activities and retreats.",
              "Provide mentorship and guidance to young leaders.",
            ],
            event: [
              {
                title: "Youth Retreat 2024",
                date: "March 15, 2024",
                description: "A weekend retreat to inspire and connect with God.",
              },
              {
                title: "Community Outreach",
                date: "April 10, 2024",
                description:
                  "Join us in serving the local community through outreach programs.",
              },
            ],
            resources: [
              { name: "Bible Study Guide", url: "#" },
              { name: "Youth Ministry Handbook", url: "#" },
            ],
            testimonies: [
              {
                name: "Jane Smith",
                message:
                  "The Youth Ministry has transformed my life! I found a community of friends and mentors.",
              },
            ],
          }}
        />    </ThemeProvider>
    )
}