import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchChannel, fetchVideosFromPlaylist, fetchLiveVideosFromChannel } from '../youtubemanager/youtubeService';
import { Channel, Video } from '../../utils/types/youtubeModel';

interface YoutubeState {
  channel: Channel | null;
  videos: Video[];
  liveVideos: Video[];
  nextPageToken: string | null; // Add nextPageToken for pagination
  loading: boolean;
  error: string | null;
}

const initialState: YoutubeState = {
  channel: null,
  videos: [],
  liveVideos: [],
  nextPageToken: null, // Initialize nextPageToken
  loading: false,
  error: null,
};

// Fetch channel and its videos (initial and paginated)
export const getChannel = createAsyncThunk(
  'youtube/getChannel',
  async ({ channelId, pageToken }: { channelId: string; pageToken: string }, { rejectWithValue }) => {
    try {
      // If it's the first page, fetch the channel details
      const channel = pageToken ? null : await fetchChannel(channelId);

      // Fetch videos from the channel's upload playlist
      const { videos, nextPageToken } = await fetchVideosFromPlaylist(
        channel?.uploadPlaylistId || '',
        pageToken
      );

      return { channel, videos, nextPageToken };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Fetch live videos
export const getLiveVideos = createAsyncThunk(
  'youtube/getLiveVideos',
  async (channelId: string, { rejectWithValue }) => {
    try {
      const liveVideos = await fetchLiveVideosFromChannel(channelId);
      return liveVideos;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const youtubeSlice = createSlice({
  name: 'youtube',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle getChannel
      .addCase(getChannel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getChannel.fulfilled,
        (state, action: PayloadAction<{ channel: Channel | null; videos: Video[]; nextPageToken: string | null }>) => {
          state.loading = false;

          // Update channel only if it's the first page
          if (action.payload.channel) {
            state.channel = action.payload.channel;
          }

          // Append new videos to the existing list
          state.videos = [...state.videos, ...action.payload.videos];

          // Update the nextPageToken for pagination
          state.nextPageToken = action.payload.nextPageToken;
        }
      )
      .addCase(getChannel.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle getLiveVideos
      .addCase(getLiveVideos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLiveVideos.fulfilled, (state, action: PayloadAction<Video[]>) => {
        state.loading = false;
        state.liveVideos = action.payload;
      })
      .addCase(getLiveVideos.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default youtubeSlice.reducer;