import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { deleteUser, getUsers, modifyUser, updatePartnership } from "./userManagementService";
import { UsersState, Member } from "../../utils/types/userManagement";

const initialState: UsersState = {
  members: [], // Array of members
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

// Helper function for toast notifications
const displayToast = (type: "success" | "error", message: string) => {
  if (type === "success") toast.success(message);
  else if (type === "error") toast.error(message);
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Users (Members)
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.members = action.payload || []; // Set members array
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload || "Failed to fetch members!";
        displayToast("error", state.error!); // Show error toast
      })

      // Modify Member
      .addCase(modifyUser.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.error = null;
      })
      .addCase(modifyUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.successMessage = "Member modified successfully!";
        displayToast("success", "Member modified successfully!"); // Show success toast

        const modifiedMember = action.payload;
        if (modifiedMember) {
          state.members = state.members.map((member) =>
            member.authid === modifiedMember.authid ? modifiedMember : member
          );
        }
      })
      .addCase(modifyUser.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload || "Failed to modify member!";
        displayToast("error", state.error!); // Show error toast
      })

      // Delete Member
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.successMessage = "Member deleted successfully!";
        displayToast("success", "Member deleted successfully!"); // Show success toast

        const deletedMemberId = action.payload?.id;
        if (deletedMemberId) {
          state.members = state.members.filter(
            (member) => member.authid !== deletedMemberId
          );
        }
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload || "Failed to delete member!";
        displayToast("error", state.error!); // Show error toast
      })

      // Update Member Partnerships
      .addCase(updatePartnership.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.error = null;
      })
      .addCase(updatePartnership.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.successMessage = "Partnership updated successfully!";
        displayToast("success", "Partnership updated successfully!"); // Show success toast
      })
      .addCase(updatePartnership.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload || "Failed to update partnership!";
        displayToast("error", state.error!); // Show error toast
      });
  },
});

export const { refreshState } = usersSlice.actions;

export default usersSlice.reducer;