import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  modifyAuthUser,
  registerUser,
  resetPassword,
  getCurrentUser, // Import the new thunk
} from "./authService";
import { AuthState } from "../../utils/types";
import { Member, User } from "../../utils/types/userManagement";

const initialState: AuthState = {
  user: null,
  isLoading: false,
  accountState: null,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  authenticated: false,
  successMessage: null,
  signupData: null,
  paymentSelected: false,
  payment: null,
  isMember: false,
  password:null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.hasError = false;
      state.isSuccess = false;
      state.hasWarning = false;
      state.error = "";
    },
    logout: () => {
      localStorage.clear();
      return initialState;
    },
    selectPackage: (state, action) => {
      state.payment = action.payload;
    },
    updatePassword: (state, action) => {
      state.password = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.hasWarning = false;
        state.hasError = false;
        state.isSuccess = false;
        state.authenticated = false;
        state.signupData = action.meta.arg;
      })
      .addCase(modifyAuthUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccess = false;
        state.hasWarning = false;
        state.hasError = false;
      })
      .addCase(modifyAuthUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;

        if (action.payload.user) {
          state.user = action.payload.user;
          state.isMember = true;
        }

        state.successMessage = action.payload.message;
      })
      .addCase(modifyAuthUser.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        state.error = action.payload as string;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.accountState = "created";
        state.authenticated = true;

        if (action.payload && (action.payload as Member).role) {
          state.isMember = true;
          state.user = action.payload as Member;
        } else {
          state.isMember = false;
          state.user = action.payload as User;
        }
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        console.log("error to signup", action.payload);
        state.error = action.payload as unknown as string;
        state.accountState = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccess = false;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.authenticated = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        state.user = null;
        state.error = action.payload as string;
        state.accountState = null;
        state.authenticated = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasWarning = false;
        state.hasError = false;
        state.isSuccess = true;

        if (action.payload === null) {
          state.error = "Login failed";
          state.authenticated = false;
        } else {
          state.error = null;
          state.authenticated = true;

          if ((action.payload as Member).role) {
            state.isMember = true;
            state.user = action.payload as Member;
          } else {
            state.isMember = false;
            state.user = action.payload as User;
          }
        }
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccess = false;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        state.user = null;
        state.error = (action.payload as string)[0];
        state.accountState = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasWarning = false;
        state.hasError = false;
        state.isSuccess = true;
        state.successMessage =
          "Password reset link sent. please check your email !";
        console.log("reset has fulfiled with", action.payload);
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccess = false;
        state.hasWarning = false;
        state.hasError = false;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;

        if (action.payload === null) {
          state.error = "User not found";
          state.authenticated = false;
        } else {
          state.error = null;
          state.authenticated = true;

          if ((action.payload as Member).role) {
            state.isMember = true;
            state.user = action.payload as Member;
          } else {
            state.isMember = false;
            state.user = action.payload as User;
          }
        }
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        state.error = action.payload as string;
        state.authenticated = false;
      });
  },
});

export const { refreshState, selectPackage, logout, updatePassword  } = authSlice.actions;
export default authSlice.reducer;