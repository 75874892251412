import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  useTheme,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // For routing
import { styled } from "@mui/system";
import img1 from "../../assets/images/7.jpg";
import img2 from "../../assets/images/5.jpg";
import img3 from "../../assets/images/8.jpg";
import img4 from "../../assets/images/11.jpg";
import bg from "../../assets/images/tecglobalbg.png";
const MinistriesPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate(); // Hook for navigation

  // Ministries data
  const ministries = [
    {
      title: "Youth Ministry",
      description:
        "Empowering the next generation through worship, mentorship, and community outreach.",
      image: img1,
      path: "/youth",
    },
    {
      title: "Women’s Ministry",
      description:
        "A community of women growing in faith, supporting one another, and serving the community.",
      image: img2,
      path: "/women",
    },
    {
      title: "Men’s Ministry",
      description:
        "Encouraging men to grow spiritually, lead their families, and serve the church and beyond.",
      image: img3,
      path: "/ministries/men",
    },
    {
      title: "Music Ministry",
      description:
        "Leading the congregation in worship and praise through music and song.",
      image: img4,
      path: "/ministries/music",
    },
    {
      title: "Children’s Ministry",
      description:
        "Teaching children about God’s word in a fun, safe, and nurturing environment.",
      image: img1,
      path: "/children",
    },
    {
      title: "Outreach Ministry",
      description:
        "Serving the community and sharing God’s love through acts of kindness and service.",
      image: img2,
      path: "/outreach",
    },
  ];

  // Styled components
  const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: "20px",
    overflow: "hidden",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    transition: "transform 0.4s ease, box-shadow 0.4s ease",
    "&:hover": {
      transform: "translateY(-10px)",
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
  }));

  const StyledMedia = styled(CardMedia)({
    height: 250,
    filter: "brightness(85%)",
    transition: "filter 0.4s ease",
    "&:hover": {
      filter: "brightness(100%)",
    },
  });

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          backgroundImage: `url(${bg})`,

          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme.palette.common.white,
          textAlign: "center",
          px: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <Typography
            variant="h4" // Changed to h4 for a smaller heading
            sx={{
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: "2px",
              marginBottom: "10px",
              lineHeight: 1.2, // Added line-height for better spacing
            }}
          >
            Discover Our Ministries
          </Typography>
          <Typography variant="h6" sx={{ maxWidth: "600px", margin: "0 auto" }}>
            Grow your faith and serve the community by joining one of our
            ministries. There’s a place for everyone here.
          </Typography>
        </Box>
      </Box>

      {/* Ministries Section */}
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: theme.palette.primary.main,
            textTransform: "uppercase",
            letterSpacing: "2px",
          }}
        >
          Our Ministries
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          sx={{
            fontSize: "1.2rem",
            maxWidth: "700px",
            margin: "0 auto",
            marginBottom: 5,
          }}
        >
          Explore the different ministries and find your calling.
        </Typography>

        {/* Ministries Grid */}
        <Grid container spacing={4}>
          {ministries.map((ministry, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledCard>
                {/* Ministry Image */}
                <StyledMedia image={ministry.image} title={ministry.title} />
                {/* Ministry Content */}
                <CardContent
                  sx={{
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      color: theme.palette.primary.dark,
                      marginBottom: "10px",
                    }}
                  >
                    {ministry.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      marginBottom: "20px",
                      fontSize: "1rem",
                    }}
                  >
                    {ministry.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    sx={{
                      borderRadius: "50px",
                      textTransform: "capitalize",
                      fontSize: "0.9rem",
                      padding: "8px 20px",
                    }}
                    onClick={() => navigate(ministry.path)}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default MinistriesPage;
