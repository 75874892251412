import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react";
// Import your reducers
import authReducer from "./auth/authSlice";
import ContactFormReducer from "./contactForm/contactFormSlice";
import faqsReducer from "./faqManagement/questions/questionsSlice";
import faqCategoriesReducer from "./faqManagement/categories/categoriesSlice";
import contactsReducer from "./contacts/contactsSlice";
import usersReducer from "./userManagement/userManagementSlice";
import newslettersReducer from "./newsletters/newslettersSlice";
import newsLetterReducer from "./newsLetterSubscription/newsLetterSlice";

import blogPostsReducer from "./blogManagement/posts/postsSlice";
import blogCategoriesReducer from "./blogManagement/categories/categoriesSlice";
import blogTagsReducer from "./blogManagement/tags/tagsSlice";

import eventsReducer from "./eventsManager/eventsSlice";
import postReducer from "./postManagement/postSlice";
import testimonyreducer from "./testimonyManager/testimonySlice";
import youtubeReducer from "./youtubemanager/youtubeSliece";

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  newsLetter: newsLetterReducer,
  users: usersReducer,

  newsletters: newslettersReducer,
  faqCategories: faqCategoriesReducer,
  faqs: faqsReducer,
  ContactForm: ContactFormReducer,
  contacts: contactsReducer,

  blogPosts: blogPostsReducer,
  blogCategories: blogCategoriesReducer,
  blogTags: blogTagsReducer,
  events: eventsReducer,
  posts: postReducer,
  testimonies: testimonyreducer,
  youtube: youtubeReducer,
});

// Redux Persist configuration
const persistConfig = {
  key: "root", // Key for storing the state
  storage, // Storage backend (localStorage by default)
  whitelist: ["auth",], // Specify which slices of state to persist
  // blacklist can also be used to exclude certain reducers
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore actions with a non-serializable payload caused by redux-persist
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

// Create the persistor
export const persistor = persistStore(store);

// Types for state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Typed versions of useDispatch and useSelector
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
