import {
  Box,
  Grid,
  Collapse,
  ListItemIcon,
  ListItemButton,
  Card,
  Typography,
  IconButton,
  styled,
} from "@mui/material";

export const ComponentContainer = styled(Grid)(({ theme }) => ({
  height: "98%",
  [theme.breakpoints.down("md")]: {
    // display: "none",
  },
}));
export const LeftDrawerContaner = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: "100",
  left: "0",
  display: "flex",
  alignItems: "center",
  height: "50px",
  zIndex: 1,

  [theme.breakpoints.up("md")]: {
    // height: "100%",
    display: "none",
  },
}));
export const MenueButtonContainer = styled(Box)(({ theme }) => ({
  width: 78,
  display: "flex",
  justifyContent: "center",
}));
export const InnerContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "63px",
  background: theme.palette.primary.main,
  borderRadius: "10px 10px 0px 0px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.primary.contrastText,
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "20px",
  lineHeight: "30px",
}));
export const MainContainer = styled(Box)(({ theme }) => ({
  width: "23vw",
  height: "100%",
  background: theme.palette.background.paper,
  borderRadius: "9px",
  paddingBottom: "10px",
  marginBottom: "10px",
  [theme.breakpoints.down("md")]: {
    // height: "100%",
    display: "none",
  },
}));

export const CollapseContainer = styled(Collapse)(({ theme }) => ({
  width: "23vw",
  height: "100%",
  background: theme.palette.background.paper,
  borderRadius: "9px",
  paddingBottom: "10px",
  marginBottom: "10px",
  [theme.breakpoints.down("md")]: {
    // height: "100%",
    display: "none",
    
  height: "63px",
  background: theme.palette.primary.main,
  borderRadius: "10px 10px 0px 0px",
 // display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.primary.contrastText,
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "20px",
  lineHeight: "30px",
},  }));

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "Poppins",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "15px",
  color: theme.palette.text.primary,
}));

export const MenuIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 5,
  color: theme.palette.primary.contrastText,
  cursor: "pointer",
  ":hover": {},
}));

export const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  color: theme.palette.text.primary,
  transform: "scale(0.8)",
  paddingRight: "12px",
}));

export const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
  fontSize: "200px",
  fontFamily: "Poppins",
  display: "flex",
  justifyContent: "center",
  padding: "12px 10px",
  color: theme.palette.text.primary,
}));

export const CustomListItemText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: 300,
  color: theme.palette.text.primary,
}));

export const CustomSubListItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: "50px",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "10px",
  color: theme.palette.text.primary,
}));

export const MessageBadgeCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  width: 25,
  height: 25,
  borderRadius: 6,
  fontSize: 20,
  fontWeight: 900,
  display: "flex",
  justifyContent: "center",
}));
