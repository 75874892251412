import React, { Suspense, useEffect, useState } from "react";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import MobileDrawer from "../Drawer/MobileDrawer";
import { useLocation } from "react-router-dom";
import AdministratorLeftSection from "../Dashboard/LeftSection/Administrator";
import LeftSection from "../Dashboard/LeftSection/Teacher";
import Loading from "../../utils/loading";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getAuth } from "firebase/auth";
import { logout } from "../../redux/auth/authSlice";
import { getCurrentUser, login } from "../../redux/auth/authService";
import { LoginData } from "../../utils/types";

interface WebsiteLayoutProps {
  children: React.ReactNode;
  toggleTheme: boolean;
  setToggleTheme: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  light: boolean;
  setLight: React.Dispatch<React.SetStateAction<boolean>>;
}

const Layout: React.FC<WebsiteLayoutProps> = ({
  children,
  toggleTheme,
  setToggleTheme,
  toggle,
  setToggle,
  light,
  setLight,
}) => {
  const router = useLocation();
  const pathname = router.pathname.split("/");
  const userType = pathname[2];
  const inDashboard = router.pathname.includes("/dashboard");
  const [shrink, setShrink] = useState(false);
  const dispatch = useAppDispatch();
  const [rightWidth, setRightWidth] = useState(9);
  const [isFirebaseInitializing, setIsFirebaseInitializing] = useState(true);

  const { user, isLoading, password } = useAppSelector((state) => state.auth);
  const auth = getAuth();

  // Fetch the current user on mount
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  // Listen to Firebase Authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser === null && user !== null && password) {
        // If Firebase auth is null but the user is available in Redux state, log them in
        const loginData: LoginData = {
          user_info: user.email, // Use the email from the Redux user state
          password: password, // Use the password from the Redux user state
          stayLogged: true, // Optionally keep the user logged in
        };
        dispatch(login(loginData)); // Dispatch the login action
      }
      setIsFirebaseInitializing(false); // Firebase auth state has initialized
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [user, password, dispatch]);

  // Update right width when shrink state changes
  useEffect(() => {
    setRightWidth(shrink ? 11 : 9);
  }, [shrink]);

  const handleShrink = () => {
    setShrink((prev) => !prev);
  };

  const handleThemeToggle = () => {
    setLight((prev) => !prev);
  };

  const renderLeftSection = () => {
    switch (userType) {
      case "teacher":
        return <LeftSection shrink={shrink} handleShrink={handleShrink} />;
      case "administrator":
        return (
          <AdministratorLeftSection
            shrink={shrink}
            handleShrink={handleShrink}
          />
        );
      default:
        return null;
    }
  };



  return (
    <Wrapper>
      <Navbar
        toggle={toggle}
        setToggle={setToggle}
        toggleTheme={toggleTheme}
        setToggleTheme={setToggleTheme}
        light={light}
        setLight={setLight}
      />

      <MobileDrawer
        toggle={toggle}
        setToggle={setToggle}
        light={light}
        setThemeToggle={setToggleTheme}
        setLight={setLight}
      />

      {inDashboard ? (
        <BodyContainer>
          <Grid container spacing={2}>
            <Grid item lg="auto" md="auto" sm={12} xs={12}>
              <LeftContainer>{renderLeftSection()}</LeftContainer>
            </Grid>
            <Grid item lg={rightWidth} md={rightWidth} sm={12} xs={12}>
              <RightContainer>
                <Suspense fallback={<Loading />}>
                  <MainContainer>{children}</MainContainer>
                </Suspense>
              </RightContainer>
            </Grid>
          </Grid>
        </BodyContainer>
      ) : (
        <MainContainer>{children}</MainContainer>
      )}

      <Footer />
    </Wrapper>
  );
};

export default Layout;

const Wrapper = styled(Box)({
  maxWidth: "100%",
  margin: "0 auto",
  position: "relative",
});

const BodyContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: "50px 15px 15px 15px",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("lg")]: {
    padding: "50px 5px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 15px",
  },
}));

const LeftContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  [theme.breakpoints.up("md")]: {
    minHeight: "600px",
  },
}));

const RightContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    padding: "0 0 0 10px",
  },
  color: "#000",
}));

const MainContainer = styled(Box)({
  padding: "20px 0",
});