import { Avatar, Box, Paper, styled, Typography } from "@mui/material";

export const ReviewCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    borderRadius: '15px',
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    background: theme.palette.background.paper,
    textAlign: 'center',
    minWidth: '300px'
}))


export const ReviewAvatar = styled(Avatar)(({ theme }) => ({
    width: '70px', height: '70px', margin: '0 auto', marginBottom: theme.spacing(2), backgroundColor: theme.palette.primary.light
}))

export const ReviewContainer = styled(Box)(({theme})=>({
    display:'flex',
    overflowX:'auto',
    scrollBehavior:'smooth',
    padding:theme.spacing(2),
    gap:theme.spacing(3),
    "&::-webkit-slider-thumb":{
        background:theme.palette.primary.main,
        borderRadius:'10px'
    }
}))

export const SectionTitle = styled(Typography)(({theme})=>({
    fontWeight:'bold',
    textAlign:'center',
    margin:theme.spacing(4),
}))

