import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  addNewsletter,
  deleteNewsletter,
  getNewsletters,
  updateNewsletter,
} from "./newslettersService";
import { NewsLettersState } from "../../utils/types/newsletters";

const initialState: NewsLettersState = {
  newsletters: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

export const newslettersSlice = createSlice({
  name: "newsletters",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsletters.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })

      .addCase(getNewsletters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = null;
        state.newsletters = action.payload || [];
      })
      .addCase(getNewsletters.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to fetch newsletters!";
      })
      .addCase(addNewsletter.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(addNewsletter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Newsletter created successfully!";
        toast.success("Newsletter created successfully!");
        const newNewsletter = action.payload;
        state.newsletters = [...state.newsletters, newNewsletter];
      })
      .addCase(addNewsletter.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to create Newsletter!";
      })
      .addCase(updateNewsletter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Newsletter updated successfully!";
        toast.success("Newsletter updated successfully!");

        const updatedNewsletter = action.payload;
        const index = state.newsletters.findIndex(
          (newsletter) => newsletter.id === updatedNewsletter.id
        );
        if (index !== -1) {
          state.newsletters[index] = updatedNewsletter;
        }
      })
      .addCase(updateNewsletter.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to update Newsletter";
      })
      .addCase(deleteNewsletter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Newsletter deleted successfully!";

        const deletedNewsletterId = action.payload?.id;

        if (deletedNewsletterId) {
          state.newsletters = state.newsletters.filter(
            (newsletter) => newsletter.id !== deletedNewsletterId
          );
        }
      })
      .addCase(deleteNewsletter.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to delete Newsletter";
      });
  },
});

export default newslettersSlice.reducer;
