
import { createSlice } from "@reduxjs/toolkit";
import { contactForm } from "./contactFormService";
import { TcontactForm } from "../../utils/types";

const initialState: TcontactForm = {
  successMessage: {
    message: "",
  },
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: "",
  contact_message: {
    first_name: "",
    last_name: "",
    email: "",
    subject: "",
    message: "",
  },
};

export const ContactForm = createSlice({
  name: "ContactForm",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.hasError = false;
      state.isSuccess = false;
      state.hasWarning = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactForm.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.hasWarning = false;
        state.hasError = false;
        state.isSuccess = false;
      })
      .addCase(contactForm.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.user = action.payload;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = action.payload;
      })
      .addCase(contactForm.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        state.error = action.payload as unknown as string;
      });
  },
});

export const { refreshState } = ContactForm.actions;
export default ContactForm.reducer;
