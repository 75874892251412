// src/App.tsx
import React from 'react';
import { Provider } from 'react-redux';
import ChannelDetails from '../components/Youtube/ChannelDetails';
import LiveVideos from '../components/Youtube/LiveVideos';
import { Background } from '../components/HomeContainer.style';

const TV: React.FC = () => {
  const channelId = 'UCU9FjNTJZVxFbeEf8hDhBvg'; // Replace with a valid YouTube Channel ID

  return (

      <Background>

    <LiveVideos channelId={channelId} />
        <ChannelDetails channelId={channelId} />
     
      </Background>

  );
};

export default TV;