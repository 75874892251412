import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  addContact,
  deleteContact,
  getContacts,
  updateContact,
} from "./contactsService";
import { ContactsState } from "../../utils/types/contacts";

const initialState: ContactsState = {
  contacts: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContacts.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })

      .addCase(getContacts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = null;
        state.contacts = action.payload || [];
      })
      .addCase(getContacts.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to fetch contacts!";
      })
      .addCase(addContact.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(addContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Contact created successfully!";
        toast.success("Contact created successfully!");
        const newContact = action.payload;
        state.contacts = [...state.contacts, newContact];
      })
      .addCase(addContact.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to create Contact!";
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Contact updated successfully!";
        toast.success("Contact updated successfully!");

        const updatedContact = action.payload;
        const index = state.contacts.findIndex(
          (newsletter) => newsletter.id === updatedContact.id
        );
        if (index !== -1) {
          state.contacts[index] = updatedContact;
        }
      })
      .addCase(updateContact.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to update Contact";
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Contact deleted successfully!";

        const deletedContactId = action.payload?.id;

        if (deletedContactId) {
          state.contacts = state.contacts.filter(
            (contact) => contact.id !== deletedContactId
          );
        }
      })
      .addCase(deleteContact.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to delete Contact";
      });
  },
});

export default contactsSlice.reducer;
