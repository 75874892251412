import React from "react";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import AboutRev from "../components/tec/about/aboutRev/aboutRev";
import DynamicHero from "../church/components/Blog/Hero/Hero";
export const Background = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: {
    background: theme.palette.background.default,
  },
  [theme.breakpoints.down("sm")]: {
    background: theme.palette.background.default,
  },
}));

const About = () => {
  return (
    <>
    <DynamicHero title="About " />
    <AboutRev /></>
  );
};

export default About;
