import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Button,
  IconButton,
  ListItemIcon,
  ListItemButton,
  Card,
  Typography,
  Collapse,
  ListItemButtonProps,
  List,
} from "@mui/material";



interface CustomListItemButtonProps extends ListItemButtonProps {
  active?: any;
}

export const ComponentContainer = styled(Grid)(({ theme }) => ({
  height: "98%",
  [theme.breakpoints.down("md")]: {
    // display: "none",
  },
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  width: "23vw",
  height: "100%",
  background: theme.palette.background.paper,
  borderRadius: "9px",
  paddingBottom: "10px",
  marginBottom: "10px",
  [theme.breakpoints.down("md")]: {
    // height: "100%",
    display: "none",
  },
}));
export const CollapseContainer = styled(Collapse)(({ theme }) => ({
  width: "23vw",
  height: "100%",
  background: theme.palette.background.default,
  borderRadius: "9px",
  paddingBottom: "10px",
  marginBottom: "10px",
  [theme.breakpoints.down("md")]: {
    // height: "100%",
    display: "none",
  },
}));

export const LeftDrawerContaner = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: "100",
  left: "0",
  display: "flex",
  alignItems: "center",
  height: "50px",
  zIndex: 1,

  [theme.breakpoints.up("md")]: {
    // height: "100%",
    display: "none",
  },
}));

export const InnerContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "63px",
  background: theme.palette.primary.main,
  borderRadius: "10px 10px 0px 0px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.primary.contrastText,
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "20px",
  lineHeight: "30px",
}));
export const DownloadCard = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "63px",
  background: theme.palette.primary.main,
  borderRadius: "0px 0px 10px 10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.primary.contrastText,
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: "20px",
  lineHeight: "30px",
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "Poppins",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "15px",
  color: theme.palette.text.primary,
}));

export const MenueButtonContainer = styled(Box)(({ theme }) => ({
  width: 78,
  display: "flex",
  justifyContent: "center",
}));

export const MenuIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 5,
  color: theme.palette.text.primary,
  cursor: "pointer",
  ":hover": {},
}));

export const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  color: theme.palette.text.primary,
  transform: "scale(0.8)",
  paddingRight: "12px",
}));

export const CustomListItemButton = styled(
  ListItemButton
)<CustomListItemButtonProps>(({ theme, active }) => ({
  fontSize: "200px",
  fontFamily: "Poppins",
  display: "flex",
  justifyContent: "center",
  padding: "12px 10px",
  backgroundColor: active ? theme.palette.background.paper : "inherit",
  boxShadow: active
    ? "0px 4px 20px 0px rgba(0, 0, 0, 0.15)"
    : "none",
  borderRadius: "6px",
}));

export const CustomListItemText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: 300,
  color: theme.palette.text.primary,
}));
export const CustomSubListItemButton = styled(ListItemButton)({
  paddingLeft: "50px",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "10px",
});
export const MessageBadgeCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  width: 25,
  height: 25,
  borderRadius: 6,
  fontSize: 20,
  fontWeight: 900,
  display: "flex",
  justifyContent: "center",
}));

export const PaymentBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const PaymentCard = styled(Card)(({ theme }) => ({
  background: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "75%",
  borderRadius: 10,
}));

export const CreditCardImage = styled(Box)({
  width: 30,
  height: 30,
  border: "1px solid #C5C5C5",
  padding: 10,
  borderRadius: 10,
  marginTop: "10px",
});
export const DropDownImage = styled(Box)({
  width: 32,
  height: 102,
  padding: "5px 0",
});

export const PaymentGridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px 15px",
});

export const PaymentHeading = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "Poppins",
  color: theme.palette.primary.main,
}));

export const PaymentGridLabel = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Poppins",
  color: theme.palette.primary.main,
}));

export const PaymentGridValue = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Poppins",
  color: theme.palette.text.secondary,
}));

export const PaymentDetailBox = styled(Box)({
  width: "80%",
  padding: "5px 5px 5px 10px",
});
export const PaymentDetail = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontFamily: "Poppins",
  fontSize: 14,
  cursor: "pointer",
}));

export const DownloadButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  fontFamily: "Poppins",
  textTransform: "capitalize",
  width: "80%",
  "&:hover": {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
}));

export const DesktopMenueList = styled(List)({
  padding: "8px 14px",
});
