import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaInfoCircle,
  FaPhoneSquare,
  FaDownload,
  FaChurch,
  FaTv,
  FaSchool,
  FaBuilding,
  FaNewspaper,
  FaChevronDown,
} from "react-icons/fa";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../redux/store";

interface DrawerProps {
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  light: boolean;
  setThemeToggle: React.Dispatch<React.SetStateAction<boolean>>;
  setLight: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerMenu: React.FC<DrawerProps> = ({
  toggle,
  setToggle,
  light,
  setThemeToggle,
  setLight,
}) => {
  const theme = useTheme();
  const [openChurch, setOpenChurch] = useState(false);
  const [openTV, setOpenTV] = useState(false);
  const [openSchool, setOpenSchool] = useState(false);
  const [openFoundation, setOpenFoundation] = useState(false);
  const schoolDomain = "https://school.empowermentrevolution.org";
  const foundationDomain = "https://foundation.empowermentrevolution.org";
  const handleDrawerToggle = () => {
    setToggle(!toggle);
  };
  const { authenticated, user, isMember } = useAppSelector(
    (state) => state.auth
  );
  const [authen, setauthen] = useState(false);

    useEffect(() => {
      if (authenticated && user) {
        setauthen(true);
      }
    }, [authenticated, user, setauthen]);
  const handleThemeToggle = () => {
    setLight(!light);
    setThemeToggle(!light);
  };

  return (
    <>
      <Drawer anchor="left" open={toggle} onClose={handleDrawerToggle}>
        <Box
          sx={{
            width: 250,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* HEADER */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            }}
          >
            <Typography variant="h6">Menu</Typography>
            <IconButton
              onClick={handleDrawerToggle}
              sx={{ color: theme.palette.common.white }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Divider />

          {/* MENU ITEMS */}
          <List>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/" onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <FaHome />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>

            {/* Church Dropdown */}
            <ListItem disablePadding>
              <ListItemButton onClick={() => setOpenChurch(!openChurch)}>
                <ListItemIcon>
                  <FaChurch />
                </ListItemIcon>
                <ListItemText primary="Church" />
                <FaChevronDown />
              </ListItemButton>
            </ListItem>
            <Collapse in={openChurch} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link} to="/church" onClick={handleDrawerToggle}>
                  <ListItemText primary="Home" />
                </ListItemButton>
                <ListItemButton component={Link} to="/church/ministries" onClick={handleDrawerToggle}>
                  <ListItemText primary="Ministries" />
                </ListItemButton>
                <ListItemButton component={Link} to="/church/event" onClick={handleDrawerToggle}>
                  <ListItemText primary="Events" />
                </ListItemButton>
                <ListItemButton component={Link} to="/church/departments" onClick={handleDrawerToggle}>
                  <ListItemText primary="Departments" />
                </ListItemButton>
                <ListItemButton component={Link} to="/church/partnership" onClick={handleDrawerToggle}>
                  <ListItemText primary="Partnership" />
                </ListItemButton>
                <ListItemButton component={Link} to="/church/testimonies" onClick={handleDrawerToggle}>
                  <ListItemText primary="Testimonies" />
                </ListItemButton>
                <ListItemButton component={Link} to="/church/give" onClick={handleDrawerToggle}>
                  <ListItemText primary="Give" />
                </ListItemButton>
                <ListItemButton component={Link} to="/church/about" onClick={handleDrawerToggle}>
                  <ListItemText primary="About" />
                </ListItemButton>
              </List>
            </Collapse>

            {/* TV Dropdown */}
            <ListItem disablePadding>
              <ListItemButton onClick={() => setOpenTV(!openTV)}>
                <ListItemIcon>
                  <FaTv />
                </ListItemIcon>
                <ListItemText primary="TV" />
                <FaChevronDown />
              </ListItemButton>
            </ListItem>
            <Collapse in={openTV} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link} to="/tv" onClick={handleDrawerToggle}>
                  <ListItemText primary="Home" />
                </ListItemButton>
                <ListItemButton component={Link} to="/tv/programs" onClick={handleDrawerToggle}>
                  <ListItemText primary="Programs" />
                </ListItemButton>
                <ListItemButton component={Link} to="/tv/workers" onClick={handleDrawerToggle}>
                  <ListItemText primary="Workers" />
                </ListItemButton>
                <ListItemButton component={Link} to="/tv/about" onClick={handleDrawerToggle}>
                  <ListItemText primary="About" />
                </ListItemButton>
              </List>
            </Collapse>

       

            {/* Other Links */}
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/about" onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <FaInfoCircle />
                </ListItemIcon>
                <ListItemText primary="About" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/blog" onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <FaNewspaper />
                </ListItemIcon>
                <ListItemText primary="Blog" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/contact" onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <FaPhoneSquare />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/download" onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <FaDownload />
                </ListItemIcon>
                <ListItemText primary="Download" />
              </ListItemButton>
            </ListItem>
          </List>

          <Divider />

          {/* DARK MODE TOGGLE */}
          <Box sx={{ padding: "10px" }}>
            <Button
              onClick={handleThemeToggle}
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                width: "100%",
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              {light ? <LightModeIcon /> : <DarkModeIcon />}
              <Typography sx={{ marginLeft: "10px" }}>
                {light ? "Light Mode" : "Dark Mode"}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerMenu;