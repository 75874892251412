
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addFaqCategory,
  deleteFaqCategory,
  getCategories,
  updateFaqCategory,
} from "./categoriesService";
import { FaqCategoriesState } from "../../../utils/types/faqManagement";

const initialState: FaqCategoriesState = {
  faqCategories: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

export const faqCategoriesSlice = createSlice({
  name: "faqCategories",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })

      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = null;
        state.faqCategories = action.payload || [];
      })
      .addCase(getCategories.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to fetch posts!";
      })
      .addCase(addFaqCategory.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(addFaqCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Category created successfully!";
        toast.success("Category created successfully!");
        const newCategory = action.payload;

        state.faqCategories = [
          ...state.faqCategories,
          newCategory.faq_category,
        ];
      })
      .addCase(addFaqCategory.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to create category!";
      })
      .addCase(updateFaqCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Category updated successfully!";
        toast.success("Category updated successfully!");

        const updatedCategory = action.payload;
        const index = state.faqCategories.findIndex(
          (category) => category.id === updatedCategory.id
        );
        if (index !== -1) {
          state.faqCategories[index] = updatedCategory;
        }
      })
      .addCase(updateFaqCategory.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to update category";
      })
      .addCase(deleteFaqCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Category deleted successfully!";

        const deletedCategoryId = action.payload?.id;

        if (deletedCategoryId) {
          state.faqCategories = state.faqCategories.filter(
            (category) => category.id !== deletedCategoryId
          );
        }
      })
      .addCase(deleteFaqCategory.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to delete category";
      });
  },
});

export default faqCategoriesSlice.reducer;
