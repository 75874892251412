import userImage from "../../../../assets/user.png";

import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Box, Menu, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";

import {
  AccountImage,
  ButtonContainer,
  CustomMenuItem,
  DropdownButton,
  NavLink,
} from "./UserDropdown.styles";
import { UserRole } from "../../../../constants/enums/user";
import { logout } from "../../../../redux/auth/authSlice";
import {
  useAppDispatch,
  useAppSelector,
  RootState,
} from "../../../../redux/store";
import { Member, User } from "../../../../utils/types/userManagement";
import { getAuth } from "firebase/auth";

const UserDropdown = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const auth = getAuth();
  const { user } = useAppSelector((state: RootState) => state.auth);
  const theme = useTheme();

  // Safely handle user avatar and name
  const userAvatar =
    user && (user as Member).profilepic
      ? (user as Member).profilepic
      : auth.currentUser?.photoURL ?? userImage;

  const username = user && (user as Member).name ? (user as Member).name : "";

  // Extract only the first name (first word) from the username
  const displayName = username.split(" ")[0];

  const anchorRef = useRef(null);

  const handleLogout = () => {
    // Clear the user state and navigate to login
    dispatch(logout());
    router("/login");
  };

  const gotoProfile = () => {
    if (auth.currentUser?.uid) {
      router(`/profile/${auth.currentUser.uid}`);
    }
  };

  const dropdownIcon = (
    <KeyboardArrowDownOutlined sx={{ color: theme.palette.primary.main }} />
  );

  return (
    <Box>
      <ButtonContainer>
        <DropdownButton
          endIcon={dropdownIcon}
          onClick={() => setOpenDropdown(true)}
          disableRipple
          ref={anchorRef}
        >
          {userAvatar && (
            <img src={userAvatar} alt={"user"} width={40} height={40} />
          )}
          {/* Apply primary color to the username and display only the first name */}
          <span
            style={{ color: theme.palette.primary.main, marginLeft: "8px" }}
          >
            {displayName}
          </span>
        </DropdownButton>
      </ButtonContainer>
      <Menu
        id="account-menu"
        open={openDropdown}
        onClose={() => setOpenDropdown(false)}
        onClick={() => setOpenDropdown(false)}
        PaperProps={{
          elevation: 0,
          style: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            marginTop: "50px",
            marginRight: "20px",
            width: 180,
            height: "auto",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        anchorEl={anchorRef.current}
      >
        <CustomMenuItem></CustomMenuItem>
        <CustomMenuItem onClick={gotoProfile}>Profile</CustomMenuItem>
        <CustomMenuItem onClick={handleLogout}>Logout</CustomMenuItem>
      </Menu>
    </Box>
  );
};

export default UserDropdown;
