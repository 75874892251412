import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";

import { CardWrapperQuestion, QuestionHeading, Questions } from "./Question.styles";
import badge from "../../../assets/Faq/badge.png"; // Consider replacing this with a more academic icon
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface Faqs{
  
  question: string;
  answer : string;
}

interface FaqTitle{
  title : string;
}

const Faq : Faqs[]=[
  
{
    question:'How can i get involved in church activities?',
    answer:'We have various ministries and departments,such as the choir,youth group,prayer team and community outreach.Visit our connect page to learn more!'
},
{
    question:'Is childcare available during services?',
    answer:'Yes we provide childcare during all our services,ensuring a safe environment for your little ones'
},

]
const QuestionDetail1 :React.FC<FaqTitle>=({title})=> {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <CardWrapperQuestion>
      <Grid container justifyContent="flex-start" spacing={3}>
        <Grid item xs={12}>
          <QuestionHeading>
            <img src={badge} alt="badge" />
            <Typography variant="h2">{title}</Typography>
          </QuestionHeading>
        </Grid>

        {/* First Question with Answer */}
        {Faq.map((faq, index)=>(
          <Grid item xs={12}>
          <Questions onClick={() => handleClick(0)} align="">
            <Typography variant="h2">{faq.question}</Typography>
            {openIndex === 0 ? <RemoveIcon /> : <AddIcon />}
          </Questions>
          {openIndex === 0 && (
            <Questions align="column">
              <Typography variant="body1">
               {faq.answer}
              </Typography>
            </Questions>
          )}
        </Grid>
        ))}

   
      </Grid>
    </CardWrapperQuestion>
  );
};

export default QuestionDetail1;