import { Rating, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "styled-components";
import { ReviewAvatar, ReviewCard, ReviewContainer, SectionTitle } from "./review.styles";

interface Reviews{
    name:string;
    role:string;
    rating:number;
    review:string;
}


const reviews:Reviews[]=[
    {
    name:'John Doe',
    role:'Choir Member',
    rating:5,
    review:'This church has transformed my life. The aermons are powerful and the community is so loving!'
    },
    {
        name:'Jane Smith',
        role:'Yoth Leader',
        rating:4.5,
        review:'A truly welcoming place to worship. The youth programs are engaging and inspiring!'
        },
        {
            name:'Micheal Brown',
            role:'Sunday School Teacher',
            rating:5,
            review:'I have grown so much spiritually here. The dedication to teaching God"s word is incredible!'
            },{
                name:'Emily Davis',
                role:'Usher',
                rating:4,
                review:'The worship sessions are uplifting and i always leave feeling rejuvenated!'
                },{
                    name:'Chris Johnson',
                    role:' Member',
                    rating:4.8,
                    review:'a church that truly embodies love and faith.I am so blessed to be part of this family!'
                    }
]

const Review :React.FC=()=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return(
        <Box sx={{padding:theme.spacing(4),background:theme.palette.grey[100]}}>
        <SectionTitle variant={isMobile ?"h5":"h4"}>
            What Our Christians Say
        </SectionTitle>
        <ReviewContainer>
            {reviews.map((review,index)=>(
                <ReviewCard key={index}>
                    <ReviewAvatar>{review.name.charAt(0)}</ReviewAvatar>

                    <Typography variant="h6" gutterBottom>{review.name}</Typography>
                    <Typography variant="subtitle2" gutterBottom color="textSecondary" sx={{fontStyle:'italic'}}>{review.role}</Typography>
                    <Rating
                    value={review.rating} precision={0.5} readOnly sx={{marginBottom:2}} />
                    <Typography variant="body1" color="textSecondary" >{review.review}</Typography>
                </ReviewCard>
            ))}
        </ReviewContainer>
        
        
        </Box>
    )
}
export default Review;