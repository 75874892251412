import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Input,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  FaFacebookMessenger,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import * as Yup from "yup";
import { motion } from "framer-motion";
import HeroContact from "../heroContact/heroContact";
import Map from "../../../Layout/Map";

import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { TcontactMessage } from "../../../../utils/types";
import { contactForm } from "../../../../redux/contactForm/contactFormService";
import { toast } from "react-toastify";
import { refreshState } from "../../../../redux/contactForm/contactFormSlice";
import { Form, Formik } from "formik";

// Styled Components
const ContactScreenContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "2rem",
  width: "100%",
  background: `linear-gradient(120deg, ${theme.palette.background.default} 0%, #eaeaea 100%)`,
  minHeight: "100vh",
  overflow: "hidden",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginBottom: "2rem",
}));

const ContactContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  width: "100%",
  maxWidth: "1250px",
  backgroundColor: theme.palette.background.default,
  marginTop: "2rem",
  gap: "2rem",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ContactCard = styled(Card)(({ theme }) => ({
  flex: 1,
  margin: "1rem",
  padding: "2rem",
  justifyItems: "center",
  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  borderRadius: "16px",
  background: `linear-gradient(120deg, #ffffff, ${theme.palette.background.paper})`,
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 15px 40px rgba(0,0,0,0.2)",
  },
  [theme.breakpoints.down("md")]: {
    margin: "1rem 0",
  },
}));

const ContactCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.8rem",
  fontWeight: "bold",
  marginBottom: "1rem",
  color: "#333",
  textAlign: "center",
}));

const ContactCardText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  color: theme.palette.text.primary,
  marginBottom: "1rem",
  textAlign: "center",
}));

const ContactIcon = styled(Box)(({ theme }) => ({
  fontSize: "3rem",
  color: theme.palette.primary.main,
  marginBottom: "1rem",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
}));

const ContactForm = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "1.5rem",
  padding: "1rem",
  background: `linear-gradient(120deg, ${theme.palette.background.default},${theme.palette.background.paper} )`,
  borderRadius: "16px",
  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
}));

const FormRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const StyledInput = styled(Input)(({ theme }) => ({
  flex: 1,
  padding: "0.8rem",
  border: "1px solid #ddd",
  borderRadius: "8px",
  margin: "2px",
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
  "&:focus": {
    borderColor: "#6C63FF",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  margin: "2px",
  background: theme.palette.background.default,
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.primary.main}  100%)`,
  color: "#fff",
  padding: "1rem",
  fontSize: "1.2rem",
  width: "100%",
  borderRadius: "8px",
  boxShadow: "0px 10px 20px rgba(0,0,0,0.2)",
  textTransform: "uppercase",
  fontWeight: "bold",
  transition: "all 0.3s ease",
  margin: "2px",
  "&:hover": {
    background: "linear-gradient(120deg, #5A55FF 0%, #6C63FF 100%)",
    transform: "translateY(-3px)",
  },
}));

const MapContainer = styled(Box)(({ theme }) => ({
  marginTop: "2rem",
  width: "100%",
  height: "400px",
  borderRadius: "16px",
  overflow: "hidden",
  boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
}));

// Animation Variants
const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

export default function ContactScreen() {
  const theme = useTheme();
  const isNoneMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useAppDispatch();

  const { isLoading, isSuccess, hasError, error, successMessage } =
    useAppSelector((state) => state.ContactForm);
  const [isSubmitting, setSubmitting] = useState(false);

  const submitForm = async (values: TcontactMessage) => {
    dispatch(contactForm(values));
  };

  useEffect(() => {
    if (!isLoading && isSubmitting) {
      if (hasError && error) {
        toast.error(`Sorry your message was not sent.`);
      } else if (isSuccess && successMessage) {
        const { message } = successMessage;
        toast.success(message);
      }
      setSubmitting(false);
      dispatch(refreshState());
    }
  }, [
    dispatch,
    error,
    hasError,
    isLoading,
    isSubmitting,
    isSuccess,
    successMessage,
  ]);
  return (
    <ContactScreenContainer>
 
      <HeaderContainer>
        <br></br>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          style={{
            fontWeight: "bold",
            color: "#333",
            textAlign: "center",
          }}
        >
          Let's Connect!
        </Typography>
        <Typography
          variant="body1"
          style={{
            textAlign: "center",
            color: theme.palette.text.primary,
            marginBottom: "2rem",
            fontSize: "1.2rem",
          }}
        >
          We're here to help. Reach out to us through any of the methods below
          or fill out the form to get in touch.
        </Typography>
      </HeaderContainer>

      {/* Contact Cards */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        style={{ width: "80%" }}
      >
        <ContactContainer>
          <motion.div variants={cardVariants}>
            <ContactCard>
              <ContactIcon>
                <FaPhone />
              </ContactIcon>
              <ContactCardTitle>Call Us</ContactCardTitle>
              <ContactCardText>+237 679015081</ContactCardText>
              <Link to={`tel:+237679015081`}>
                {" "}
                <SubmitButton>Call Now</SubmitButton>{" "}
              </Link>
            </ContactCard>
          </motion.div>

          <motion.div variants={cardVariants}>
            <ContactCard>
              <ContactIcon>
                <FaEnvelope />
              </ContactIcon>
              <ContactCardTitle>Email Us</ContactCardTitle>
              <ContactCardText>
                contact@empowermentrevolution.org
              </ContactCardText>
              <Link to={`mailto:contact@empowermentrevolution.org`}>
                {" "}
                <SubmitButton>Send Email</SubmitButton>
              </Link>
            </ContactCard>
          </motion.div>

          <motion.div variants={cardVariants}>
            <ContactCard>
              <ContactIcon>
                <FaFacebookMessenger />
              </ContactIcon>
              <ContactCardTitle>Message Us</ContactCardTitle>
              <ContactCardText>Chat with our support team</ContactCardText>
              <SubmitButton>Chat Now</SubmitButton>
            </ContactCard>
          </motion.div>
        </ContactContainer>
      </motion.div>

      {/* Contact Form */}
      <motion.div
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        style={{ width: "100%" }}
      >
        <ContactForm>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              color: "#333",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            Book a Meeting
          </Typography>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
            }}
            validationSchema={Yup.object({
              first_name: Yup.string()
                .max(10, "Must be 10 characters or less")
                .required("Required"),

              last_name: Yup.string()
                .max(10, "Must be 10 characters or less")
                .required("Required"),

              email: Yup.string()
                .email("Invalid email")
                .required("Required")
                .matches(/^(?!.*@[^,]*,)/),
              phone: Yup.string()
                .min(6, "Must be 10 characters or less")
                .required("Required"),
              subject: Yup.string().required("Required"),
              message: Yup.string()
                .max(300, "Must be 300 characters or less")
                .required("Required"),
            })}
            onSubmit={(values, { resetForm }) => {
              setSubmitting(true);
              submitForm(values);
              resetForm({
                values: {
                  first_name: "",
                  last_name: "",
                  email: "",
                  phone: "",
                  subject: "",
                  message: "",
                },
              });
            }}
          >
            <Form>
              {" "}
              <FormRow>
                <StyledInput placeholder="First Name" name="first_name" />
                <StyledInput placeholder="Last Name" name="last_name" />
              </FormRow>
              <FormRow>
                <StyledInput placeholder="Email" name="email" />
                <StyledInput placeholder="Phone" name="phone" />
              </FormRow>
              <StyledInput
                placeholder="Subject"
                name="subject"
                style={{ width: "100%" }}
              />
              <StyledTextField
                placeholder="Message"
                multiline
                rows={4}
                name="message"
                variant="outlined"
              />
              <SubmitButton disabled={isSubmitting} type="submit">
                Submit
              </SubmitButton>
            </Form>
          </Formik>
        </ContactForm>
      </motion.div>

      {/* Map Section */}
      <motion.div
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        style={{ width: "100%" }}
      >
        <MapContainer>
          <Map />
        </MapContainer>
      </motion.div>
    </ContactScreenContainer>
  );
}
