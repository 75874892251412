import { createAsyncThunk } from "@reduxjs/toolkit";
import { addDoc, collection, getFirestore, Timestamp } from "firebase/firestore";
import { TcontactForm, TcontactMessage } from "../../utils/types";
import { db } from "../firebase";

export const contactForm = createAsyncThunk<
  TcontactForm,
  TcontactMessage
>("auth/contactForm", async (contactMessage, { rejectWithValue }) => {
  try {
    const contactData = {
      ...contactMessage,
      timestamp: Timestamp.now(),
    };

    const docRef = await addDoc(collection(db, "contacts"), contactData);

    return {
      successMessage: {
        message: `Contact saved with ID: ${docRef.id}`,
      },
      isLoading: false,
      isSuccess: true,
      hasError: false,
      hasWarning: false,
      error: null,
      contact_message: contactMessage,
    };
  } catch (error) {
    return rejectWithValue({
      successMessage: { message: "" },
      isLoading: false,
      isSuccess: false,
      hasError: true,
      hasWarning: false,
      error: "Failed to save contact message",
      contact_message: contactMessage,
    });
  }
});