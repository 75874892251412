import { Grid } from "@mui/material";
import img1 from "../../../assets/images/hi.jpg";
import img2 from "../../../assets/images/7.jpg";
import img3 from "../../../assets/images/3.jpg";
import { CardContainer, CardRow } from "./upcomingEvents.styles";
import EventCard from "./upcomingEventsCard";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import React, { useEffect } from "react";
import { getEvents } from "../../../redux/eventsManager/eventService";

interface Upcomingevent {
  id: number;
  title: string;
  description: string;
  image: string;
  path: string;
}

const EventsList: React.FC<{type:string}> = ({type}) => {
  const dispatch = useAppDispatch();
  const { events } = useAppSelector((state) => state.events);
  const filteredEvents = events.filter(
    (event) => new Date(event.startTime) >= new Date()
  );

  const sortedUpcomingEvents = [...filteredEvents].sort((a, b) => 
    new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
  );  const sortedEvents = [...events].sort((a, b) => 
    new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
);
  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);
  return (
    <CardContainer>
      <CardRow>
        { type=='upcomming' &&  sortedUpcomingEvents.map((event, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <EventCard event={event} />
          </Grid>
        ))}
          { type=='' && sortedEvents.map((event, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <EventCard event={event} />
          </Grid> 
        ))}
      </CardRow>
    </CardContainer>
  );
};
export default EventsList;
