import React, { useEffect } from "react";
import img1 from "../../../../assets/images/7.jpg";
import img2 from "../../../../assets/images/6.jpg";
import img3 from "../../../../assets/images/8.jpg";
import { Box, Card, styled, Typography, Button, Divider } from "@mui/material";
import { motion } from "framer-motion";
import { ArrowForward } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getEvents } from "../../../../redux/eventsManager/eventService";
import { EventModelProps } from "../../../../utils/types/eventsManager";

interface EventsData {
  image: string;
  title: string;
  description: string;
  event: EventModelProps;
}

const AnimatedCard = motion(Card);

const Events: React.FC<{ type: string }> = ({ type }) => {
  const dispatch = useAppDispatch();
  const { events } = useAppSelector((state) => state.events);
  const filteredEvents = events.filter(
    (event) => new Date(event.startTime) >= new Date()
  );

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  const currentDate = new Date();
  const sortedEvents = [...events].sort((a, b) => 
    new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
);
const sortedUpcomingEvents = [...filteredEvents].sort((a, b) => 
  new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
);
  return (
    <EventsContainer>
      <MainTitle>Our Latest Events</MainTitle>
      <CardsContainer>
        {type=="upcomming"&& sortedUpcomingEvents
          
          .map((event, index) => (
            <EventCard
              event={event}
              key={index}
              image={event.imageUrl}
              title={event.title}
              description={event.description}
            />
          ))}

{type=="" && sortedEvents
   
          .map((event, index) => (
            <EventCard
              event={event}
              key={index}
              image={event.imageUrl}
              title={event.title}
              description={event.description}
            />
          ))}
      </CardsContainer>
    </EventsContainer>
  );
};

const EventCard: React.FC<EventsData> = ({
  image,
  title,
  description,
  event,
}) => {
  return (
    <AnimatedCard
      whileHover={{
        scale: 1.02,
        boxShadow: "revert", // Consistent format
        translateY: -10,
      }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      sx={{
        borderRadius: "20px",
        overflow: "hidden",
        boxShadow: "blue", // Consistent format (0px spread)
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <ImageWrapper>
        <EventImage src={image} alt={title} />
        <Overlay>
          <OverlayText
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
            Discover More
          </OverlayText>
        </Overlay>
      </ImageWrapper>
      <CardContent>
        <EventTitle>{title}</EventTitle>
        <Divider
          sx={{ width: "50%", margin: "0.5rem auto", backgroundColor: "#ddd" }}
        />
        <EventDescription>{description}</EventDescription>
        <EventDescription>{event.location}</EventDescription>
        <EventDescription>{event.tags.join(", ")}</EventDescription>
        <EventDescription>
          {new Date(event.startTime).toLocaleDateString()} -{" "}
          {new Date(event.endTime).toLocaleDateString()}
        </EventDescription>
        <ActionContainer>
          <LearnMoreButton
            endIcon={<ArrowForward />}
            whileHover={{
              scale: 1.05,
              backgroundColor: "#0056b3",
            }}
            transition={{ duration: 0.3 }}
          >
            Learn More
          </LearnMoreButton>
        </ActionContainer>
      </CardContent>
    </AnimatedCard>
  );
};
export default Events;

// Styled Components

const EventsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  overflow: "hidden",
  justifyContent: "center",
  padding: "4rem 0.5rem",
  backgroundColor: theme.palette.background.default,
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  textAlign: "center",
  marginBottom: "3rem",
  fontFamily: "Poppins, sans-serif",
  letterSpacing: "2px",
  [theme.breakpoints.down("md")]: {
    fontSize: "2rem",
  },
}));

const CardsContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
  gap: "2rem",
  width: "100%",
  maxWidth: "1400px",
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "250px",
  overflow: "hidden",
}));

const EventImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  transition: "transform 0.5s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  opacity: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "opacity 0.5s ease",
  "&:hover": {
    opacity: 1,
  },
}));

const OverlayText = styled(motion(Typography))(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: "#fff",
  textTransform: "uppercase",
  letterSpacing: "1px",
}));

const CardContent = styled(Box)(({ theme }) => ({
  padding: "2rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "1.5rem",
  backgroundColor: theme.palette.background.default,
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.8rem",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  fontFamily: "'Roboto', sans-serif",
  textAlign: "center",
}));

const EventDescription = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 300,
  color: theme.palette.text.secondary,
  textAlign: "center",
  lineHeight: "1.6",
}));

const ActionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "1rem",
}));

const LearnMoreButton = styled(motion(Button))(({ theme }) => ({
  padding: "0.8rem 2rem",
  fontSize: "1rem",
  fontWeight: "bold",
  textTransform: "none",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: "50px",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
