import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../../redux/firebase";




interface FormData {
  name: string;
  email: string;
  message: string;
}

export const DataDeletionRequest: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await addDoc(collection(db, "submissions"), formData);
      setSuccess(true);
      setFormData({ name: "", email: "", message: "" }); // Reset form
    } catch (err) {
      setError("Failed to submit the request. Please try again later.");
      console.error("Error saving to Firestore:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          p: 4,
          borderRadius: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ mb: 2, fontWeight: "bold", color: "#333" }}
        >
          Data Deletion Request
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 3 }}>
          If you wish to delete your account or request the deletion of your
          personal data associated with the Tec Global app, please fill out the
          form below. Our team will process your request and notify you once it
          is completed.
        </Typography>

        {/* Success Message */}
        {success && <Alert severity="success">Request submitted successfully!</Alert>}

        {/* Error Message */}
        {error && <Alert severity="error">{error}</Alert>}

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Full Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder="Enter your full name"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Enter your email address"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Reason for Deletion (Optional)"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Tell us why you want to delete your account"
            multiline
            rows={4}
            sx={{ mb: 3 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{
              backgroundColor: "#6c63ff",
              color: "#fff",
              "&:hover": { backgroundColor: "#5a55ff" },
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Submit Request"}
          </Button>
        </form>
        <Typography
          variant="body2"
          align="center"
          color="textSecondary"
          sx={{ mt: 2 }}
        >
          Note: Once your request is processed, all your data will be permanently deleted from our systems. This action is irreversible.
        </Typography>
      </Box>
    </Container>
  );
};

