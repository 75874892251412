import React, { useEffect, useState } from "react";
import data, {  TopData } from "../../components/Blog/BlogList/BlogData";
import BlogList from "../../components/Blog/BlogList/BlogList";
import { Background } from "../about";
import BlogData from "../../components/Blog/BlogList/BlogData";
import TopAuthor from "../../components/Blog/BlogList/TopAuthor";
import DynamicHero from "../../church/components/Blog/Hero/Hero";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchPosts } from "../../redux/postManagement/postSlice";

export const getStaticProps = async () => {
  return { 
    props: { data },
  };
};
export interface BlogData{
  img:string,
  blogHeading:string,
  postedby : string,
  time:string,
  views:string,
  discreption: string
id: string
}
const Blog = () => {
 
  const dispatch = useAppDispatch();
  const { posts } = useAppSelector((state) => state.posts); // Redux state for posts
  const [blogData, setBlogData] = useState<BlogData[]>([]); // State for converted Blog Data
  const sortedPosts = [...posts].sort((a, b) => 
    new Date(b.publishdate).getTime() - new Date(a.publishdate).getTime()
  );
  useEffect(()=>{
    dispatch(fetchPosts());
  },[dispatch]);

    // Convert post list to BlogData format
    useEffect(() => {
      if (posts.length) {
        const convertedData = sortedPosts.map((post: { postid: any; authorprofilepicture: any; posttitle: any; authorName: any; publishdate: string | number | Date; postlike: string | any[]; postcontent: any; }, index: any) => ({
          id: post.postid, // Generate ID (use index or post ID from backend)
          img: post.authorprofilepicture, // Rotate images
          blogHeading: post.posttitle || "Untitled Post",
          postedby: post.authorName || "Unknown Author",
          time: new Date(post.publishdate).toLocaleDateString(),
          views: `${post.postlike.length} views`, // Example: count likes as views
          discreption: post.postcontent, // Truncate content
        }));
        setBlogData(convertedData);
      }
    }, [posts]);


  return (
    <Background>
      <DynamicHero title="Newsfeed" />
      <BlogList
        title="Latest Posts"
        title2="Post Category"
        title3="Related Posts"
        title1="Recent Post"
        data={blogData}
        showDetails={true}
        category1="Sermons"
        category2="Devotionals"
        category3="Inspirational stories" 
        category4="Bible study "
        tag1="live sessions"
        tag2="announcement"
        tag3="events"
        tag4="ministry"
        tag5="community"
        recent1="Activities of an active christian"
        recent2="What it means to be born again"
        rel1="The true purpose of a kingdom enforcer"
        rel2="Knowing what is right"
      />
      <TopAuthor
        title="Top Bloggers of the Week"
        data={TopData}
        showDetails={true}
      />
    </Background>
  );
};

export default Blog;
