import React from "react";
import Grid from "@mui/material/Grid";
import {
  Button,
  Typography,
  Checkbox,
  useMediaQuery,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

// Import styled components
import {
  AsideGrid,
  AsideGridHeading,
  AsideGridPost,
  AsideGridText,
  BlogDetail,
  BlogDetailItems,
  InputField,
  InputSearch,
  InputSearchBox,
  InputSearchWrapper,
  ChipWrapper,
  Chip,
} from "./BlogList.styles";

// Define props
interface BlogProps {
  title: string;
  title1: string;
  title2: string;
  title3: string;
  data: Blog[];
  showDetails?: boolean;
  category1: string;
  category2: string;
  category3: string;
  category4: string;
  tag1: string;
  tag2: string;
  tag3: string;
  tag4: string;
  tag5: string;
  recent1: string;
  recent2: string;
  rel1: string;
  rel2: string;
}

interface Blog {
  img: string;
  blogHeading: string;
  postedby: string;
  time: string;
  views: string;
  discreption: string;
  id: string;
}

const BlogList: React.FC<BlogProps> = ({
  title,
  title1,
  title2,
  title3,
  data,
  showDetails = true,
  recent1,
  recent2,
  rel1,
  rel2,
  tag1,
  tag2,
  tag3,
  tag4,
  tag5,
  category1,
  category2,
  category3,
  category4,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      spacing={4}
      sx={{
        padding: "0 1rem",
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Main Blog Section */}
      <Grid item xs={12} md={8}>
        {/* Search Box */}
        <InputSearchWrapper>
          <InputSearch>
            <InputSearchBox>
              <InputField
                placeholder="Search..."
                sx={{
                  "&:after, &:before": { display: "none" },
                }}
              />
              <Button
                sx={{
                  borderRadius: "0px 7px 7px 0px",
                  height: "50px",
                  border: "1px solid #1573AE",
                  textTransform: "none",
                  padding: "0 20px",
                }}
                disableRipple
                variant="contained"
              >
                Subscribe
              </Button>
            </InputSearchBox>
          </InputSearch>
        </InputSearchWrapper>

        {/* Blog Items */}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{
                marginBottom: "2rem",
                fontWeight: 600,
                color: theme.palette.text.primary,
              }}
            >
              {title}
            </Typography>
          </Grid>
          {data.map((blog, index) => (
            <Grid item xs={12} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  height: "100%",
                  boxShadow: 3,
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: isMobile ? "100%" : 200,
                    height: isMobile ? 200 : "auto",
                    objectFit: "cover",
                    borderRadius: isMobile ? "8px 8px 0 0" : "8px 0 0 8px",
                  }}
                  image={blog.img}
                  alt={blog.blogHeading}
                />
                <CardContent sx={{ flex: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      marginBottom: "1rem",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {blog.blogHeading}
                  </Typography>
                  <BlogDetail>
                    <BlogDetailItems color={theme.palette.info.dark}>
                      <PersonIcon
                        sx={{ fontSize: "14px", marginRight: "0.5rem" }}
                      />
                      <Typography variant="body2">{blog.postedby}</Typography>
                    </BlogDetailItems>
                    <BlogDetailItems color={theme.palette.info.dark}>
                      <WatchLaterIcon
                        sx={{ fontSize: "14px", marginRight: "0.5rem" }}
                      />
                      <Typography variant="body2">{blog.time}</Typography>
                    </BlogDetailItems>
                    <BlogDetailItems color={theme.palette.info.dark}>
                      <PeopleAltIcon
                        sx={{ fontSize: "14px", marginRight: "0.5rem" }}
                      />
                      <Typography variant="body2">{blog.views}</Typography>
                    </BlogDetailItems>
                  </BlogDetail>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      marginTop: "1rem",
                    }}
                  >
                    {blog.discreption}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Sidebar Section */}

<Grid item xs={12} md={4}>
  <AsideGrid container spacing={4}>
    {/* Recent Posts */}
    <Grid item xs={12}>
      <AsideGridHeading>
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, color: theme.palette.text.primary }} // Ensure this contrasts with the background
        >
          {title1}
        </Typography>
      </AsideGridHeading>
      {[recent1, recent2].map((post, index) => (
        <AsideGridText
          key={index}
          color={theme.palette.text.primary}
          shadow="round"
          sx={{ marginBottom: "1rem" }}
        >
          <Typography variant="body2">{post}</Typography>
        </AsideGridText>
      ))}
      <AsideGridText color="blue" shadow="round">
        <Typography variant="body2" color="primary">
          More...
        </Typography>
      </AsideGridText>
    </Grid>

    {/* Categories */}
    <Grid item xs={12}>
      <AsideGridHeading>
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, color: theme.palette.text.primary }} // Ensure this contrasts with the background
        >
          {title2}
        </Typography>
      </AsideGridHeading>
      {[category1, category2, category3, category4].map((category, index) => (
        <AsideGridPost key={index}>
          <Checkbox />
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.primary }} // Ensure this contrasts with the background
          >
            {category}
          </Typography>
        </AsideGridPost>
      ))}
    </Grid>

    {/* Featured Tags */}
    <Grid item xs={12}>
      <AsideGridHeading>
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, color: theme.palette.text.primary }} // Ensure this contrasts with the background
        >
          Featured Tags
        </Typography>
      </AsideGridHeading>
      <ChipWrapper>
        {[tag1, tag2, tag3, tag4, tag5].map((tag, index) => (
          <Chip key={index}>{tag}</Chip>
        ))}
      </ChipWrapper>
    </Grid>

    {/* Related Posts */}
    <Grid item xs={12}>
      <AsideGridHeading>
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, color: theme.palette.text.primary }} // Ensure this contrasts with the background
        >
          {title3}
        </Typography>
      </AsideGridHeading>
      {[rel1, rel2].map((relatedPost, index) => (
        <AsideGridText
          key={index}
          color={theme.palette.text.primary}
          shadow="round"
          sx={{ marginBottom: "1rem" }}
        >
          <Typography variant="body2">{relatedPost}</Typography>
        </AsideGridText>
      ))}
      <AsideGridText color="blue" shadow="round">
        <Typography variant="body2" color="primary">
          More...
        </Typography>
      </AsideGridText>
    </Grid>
  </AsideGrid>
</Grid>
    </Grid>
  );
};

export default BlogList;
