import Events from "../../../components/tec/home/events/events";
import UpcomingEvents from "../upcomingevents/upcomingEvents";
import { ChurchEventContainer, ChurchEventHero, ChurchEventHeroOverlay, ChurchEventHeroTitle } from "./churchEvent.styles";

export default function ChurchEvent(){
    return(<>
    <ChurchEventContainer>
        <ChurchEventHero>
            <ChurchEventHeroOverlay><ChurchEventHeroTitle>Events</ChurchEventHeroTitle></ChurchEventHeroOverlay>
        </ChurchEventHero>
        <UpcomingEvents />
      //  <Events type=""/>
    </ChurchEventContainer>
    </>)
}