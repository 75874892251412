import { styled } from "@mui/material/styles";
import { Box, Grid, Button, CircularProgress } from "@mui/material";

import img1 from "../../assets/images/tecglobalbg.png";
import { CheckBox, OutlinedInputField, OutlinedPasswordField } from "../../utils/formik/forms";

export const HeroLogin = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  backgroundImage: `url(${img1})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  padding: "7rem 0px 5px",
}));

export const ColorForm = styled(Box)(({ theme }) => ({
  width: "520px",
  height: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  background: theme.palette.background.default,
  border: "4px solid #FFFEFE",
  boxShadow: "0px 0px 61px 24px rgba(255, 255, 255, 0.5), inset 0px 0px 21px #FFFFFF",
  backdropFilter: "blur(12px)",
  borderRadius: "8px",
  padding: "20px 30px",
  margin: "20px",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    padding: "20px",
    margin: "20px 0",
  },
  "& h6": {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "25px",
    lineHeight: "30px",
    marginBottom: "30px",
    color: "#1675B7",
  },
}));

export const InputChange = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "10px",
  outline: "none",
  border: "1px solid #000000",
  borderRadius: "5px",
  marginBottom: "20px",
  padding: "5px 10px",
  color: theme.palette.text.primary,
}));

export const FieldIconContainer = styled(Box)({
  minWidth: '30px'
});

export const UserField = styled(OutlinedInputField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    width: "100%",
    outline: "none",
    borderRadius: "5px 0px 0px 5px",
    border: "none",
    paddingLeft: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "17px",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
}));

export const MyPasswordField = styled(OutlinedPasswordField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    width: "100%",
    outline: "none",
    borderRadius: "5px 0px 0px 5px",
    border: "none",
    paddingLeft: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "17px",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
}));

export const MyCheckBox = styled(CheckBox)(() => ({
  display: "inline-block",
  color: "#32393C",
  "& .error": {
    color: "red",
  },
}));

export const InputInside = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  width: "100%",
}));

export const TextGridContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: '100%',
}));

export const ForgetContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const Buttons = styled(Button)(({ theme }) => ({
  background: "purple",
  color: "#ffffff",
  borderRadius: "1px",
  height: "60px",
  width: "100%",
  textTransform: "capitalize",
  textAlign: "center",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "15px",
  lineHeight: "18.29px",
  marginTop: "30px",
  marginBottom: "30px",
  ":hover": {
    background: "#1976BB",
    color: "#ffffff",
  },
}));

export const Progress = styled(CircularProgress)({
  width: "10px",
  height: "10px",
});