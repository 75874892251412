import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getChannel } from "../../redux/youtubemanager/youtubeSliece";
import { likeVideo } from "../../utils/youtubeApi";

const shuffleArray = (array: any[]) => {
  return array
    .map((item) => ({ ...item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ sort, ...item }) => item);
};

// Utility to remove duplicate videos based on `id`
const removeDuplicateVideos = (videos: any[]) => {
  const uniqueVideos = new Map();
  videos.forEach((video) => {
    if (!uniqueVideos.has(video.id)) {
      uniqueVideos.set(video.id, video);
    }
  });
  return Array.from(uniqueVideos.values());
};

const ChannelDetails: React.FC<{ channelId: string }> = ({ channelId }) => {
  const dispatch = useAppDispatch();
  const { videos, loading, error } = useAppSelector((state) => state.youtube);

  const [shuffledVideos, setShuffledVideos] = useState<any[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  useEffect(() => {
    // Fetch videos for the channel
    dispatch(getChannel({ channelId, pageToken: "" }));
  }, [dispatch, channelId]);

  useEffect(() => {
    // Filter out duplicates and shuffle videos whenever the videos list changes
    if (videos.length > 0) {
      const uniqueVideos = removeDuplicateVideos(videos);
      setShuffledVideos(shuffleArray(uniqueVideos));
    }
  }, [videos]);

  const handleVideoClick = (videoId: string) => {
    setSelectedVideo(videoId);
  };

  const handleCloseVideo = () => {
    setSelectedVideo(null);
  };

  if (loading && !shuffledVideos.length) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      {shuffledVideos.length > 0 ? (
        <Grid container spacing={2}>
          {shuffledVideos.map((video, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                onClick={() => handleVideoClick(video.id)}
                sx={{ cursor: "pointer" }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={video.thumbnailUrl}
                  alt={video.title}
                />
                <CardContent>
                  <Typography variant="h6" noWrap>
                    {video.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No videos found.</Typography>
      )}

      {/* Video Player Dialog */}
      <Dialog
        open={!!selectedVideo}
        onClose={handleCloseVideo}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          {selectedVideo && (
            <Box sx={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                title="Video Player"
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${selectedVideo}`}
     
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => likeVideo(selectedVideo as string)}
            >
              Like
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCloseVideo}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ChannelDetails;