import { createAsyncThunk } from "@reduxjs/toolkit";
import { SignupData, LoginData } from "../../utils/types";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import { Member, User } from "../../utils/types/userManagement";
export const modifyAuthUser = createAsyncThunk<
  { user: Member | null; message: string },
  { data: Partial<Member>; id: string; },
  { rejectValue: string }
>("auth/modifyAuthUser", async ({ data, id,  }, { rejectWithValue, dispatch }) => {
  try {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      return rejectWithValue("No authenticated user found.");
    }

    // Update Firebase Authentication profile (email, password, display name, etc.)
    if (data.email) {
      await updateEmail(currentUser, data.email);
    }

    if (data.name) {
      await updateProfile(currentUser, {
        displayName: data.name,
      });
    }


    // If the user is a member, update their Firestore document
    const memberRef = doc(db, "members", id);
    const memberSnap = await getDoc(memberRef);

    if (memberSnap.exists()) {
      await updateDoc(memberRef, data);

      // Retrieve the updated member data
      const updatedMemberSnap = await getDoc(memberRef);
      if (updatedMemberSnap.exists()) {
        return {
          user: updatedMemberSnap.data() as Member,
          message: "User updated successfully.",
        };
      }
    }

    // If the user is not a member, return a success message
    return {
      user: null,
      message: "User updated successfully in Firebase Authentication.",
    };
  } catch (error: any) {
    return rejectWithValue(error.message || "Failed to update user.");
  }
});
/**
 * Register a new user
 */
export const registerUser = createAsyncThunk<
  User | Member | null,
  SignupData,
  { rejectValue: string }
>("auth/signup", async (signupData, { rejectWithValue }) => {
  try {
    const { email, password, isMember, ...rest } = signupData;

    // Create user with Firebase Auth
    const credential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const generateMembershipId = async () => {
      const snapshot = await getDocs(collection(db, "members"));
      const totalMembers = snapshot.size;
      const newMemberCount = totalMembers + 1;
      return `TEC-${newMemberCount.toString().padStart(5, "0")}`;
    };
    const membershipId = await generateMembershipId();
    if (isMember) {
      // Save additional member data to Firestore
      const memberData: Member = {
        authid: credential.user.uid,
        name: rest.fullName,
        email,
        phoneNumber: rest.phone,
        profilepic: rest.profilePic || "",
        lastPaymentDate: null,
        dateofregister: new Date().getTime(),
        lastseen: null,
        // Include member-specific fields
        membershipId: membershipId || "", // Default value if not provided
        churchBranch: rest.churchBranch || "", // Default value if not provided
        city: rest.city || "", // Default value if not provided
        tagline: rest.tagline || "", // Default value if not provided
        country: rest.country || "", // Default value if not provided
        state: rest.state || "", // Default value if not provided
        address: rest.address || "", // Default value if not provided
        dateofbirth: rest.dateofbirth.getTime() || new Date().getTime(), // Default value if not provided
        prayerRequest: rest.prayerRequest || "", // Default value if not provided
        devicetoken: "", // Default value if not provided
        role: rest.role || "member", // Default role if not provided
        isVerified: false, // Default value if not provided
        isOnline: true, // Default value if not provided
        isSuplier: false, // Default value if not provided
        mypartnerships: [], // Default value if not provided
      };

      const memberRef = doc(db, "members", credential.user.uid);
      await setDoc(memberRef, memberData);

      // Retrieve the saved member data
      const docSnap = await getDoc(memberRef);
      if (docSnap.exists()) {
        return docSnap.data() as Member;
      } else {
        return rejectWithValue("Member document does not exist in Database.");
      }
    } else {
      // Regular user, no Firestore record needed
      const userData: User = {
        uid: credential.user.uid,
        email,
        displayName: credential.user.displayName ?? "",
        phoneNumber: credential.user.phoneNumber ?? "",
      };
      return userData;
    }
  } catch (error: any) {
    console.error("Error during signup:", error);
    const errorMessage =
      error.message || "An unknown error occurred during signup.";
    return rejectWithValue(errorMessage);
  }
});
export const getCurrentUser = createAsyncThunk<
  User | Member | null, // Return type
  void, // No payload needed
  { rejectValue: string } // Reject value type
>("auth/getCurrentUser", async (_, { rejectWithValue }) => {
  try {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      return rejectWithValue("No authenticated user found.");
    }

    // Check if the user is a member by querying Firestore
    const memberRef = doc(db, "members", currentUser.uid);
    const memberSnap = await getDoc(memberRef);

    if (memberSnap.exists()) {
      // User is a member
      return memberSnap.data() as Member;
    } else {
      // User is a regular user
      const userData: User = {
        uid: currentUser.uid,
        email: currentUser.email || "",
        displayName: currentUser.displayName || "",
        phoneNumber: currentUser.phoneNumber || "",
      };
      return userData;
    }
  } catch (error: any) {
    return rejectWithValue(error.message || "Failed to fetch current user.");
  }
});
/**
 * Login an existing user
 */
export const login = createAsyncThunk<
  User | Member | null,
  LoginData,
  { rejectValue: string }
>("auth/signin", async (signinData, { rejectWithValue }) => {
  try {
    const { user_info, password } = signinData;

    // Sign in the user with email and password
    const credential = await signInWithEmailAndPassword(
      auth,
      user_info,
      password
    );

    // Check if the user is a member by querying Firestore
    const memberRef = doc(db, "members", credential.user.uid);
    const memberSnap = await getDoc(memberRef);

    if (memberSnap.exists()) {
      // User is a member
      return memberSnap.data() as Member;
    } else {
      // User is a regular user
      const userData: User = {
        uid: credential.user.uid,
        email: credential.user.email || "",
        displayName: credential.user.displayName || "",
        phoneNumber: credential.user.phoneNumber || "",
      };
      return userData;
    }
  } catch (error: any) {
    console.error("Error during login:", error);
    const errorMessage =
      error.message || "An unknown error occurred during login.";
    return rejectWithValue(errorMessage);
  }
});

/**
 * Reset user password
 */
export const resetPassword = createAsyncThunk<
  void,
  string,
  { rejectValue: string }
>("auth/resetPassword", async (email, { rejectWithValue }) => {
  try {
    // Send password reset email
    await sendPasswordResetEmail(auth, email);
  } catch (error: any) {
    console.error("Error during password reset:", error);
    const errorMessage =
      error.message || "An unknown error occurred during password reset.";
    return rejectWithValue(errorMessage);
  }
});
