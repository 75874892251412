import {  AboutCol, AboutContainer, AboutImage, AboutRow, AboutText, AboutTitle, ChurchAboutHeroContainer, ChurchAboutHeroContainerOverlay, ChurchAboutHeroText, ChurchAboutHeroTitle } from "./about.styles";
import img1 from '../../../assets/images/hi.jpg'
import img2 from '../../../assets/images/7.jpg'
import img3 from '../../../assets/images/3.jpg'
import { useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import Pastors from "../pastors/pastors";
import Counter from "../counter/counter";
import CounterList from "../counter/counterList";
import Review from "./review";
import { ThemeProvider } from "styled-components";
import theme from "../../../styles/theme";
import Question from "../../../components/FAQ/Questions/Question";

const About:React.FC =()=>{
  
    return(<><AboutContainer >
         <ChurchAboutHeroContainer>
            <ChurchAboutHeroContainerOverlay>
                <ChurchAboutHeroTitle>About Us</ChurchAboutHeroTitle>
                <ChurchAboutHeroText>Get to know more about the church!Worship with us and stay connected...</ChurchAboutHeroText>
            </ChurchAboutHeroContainerOverlay>
         </ChurchAboutHeroContainer> 
         <AboutRow>
            <AboutCol>
                <AboutCol>
                    <AboutTitle>Who are we</AboutTitle>
                    <AboutText>The Empowerment Church is an arena to train kingdom militants to grow spiritually strong,equipped and empowered through the Sword of the Spirit which is the Word of God.In the process God uses them as vessels for the manifestation of his glory through Divine purpose</AboutText>
                </AboutCol>
                
                <AboutCol>
                    <AboutTitle>What do we do</AboutTitle>
                    <AboutText>through worship, unlimited praises to God,services such as ministries, departments we spread the Love of Christ across the globe through our various branches</AboutText>
                </AboutCol>
                </AboutCol><AboutImage />
         </AboutRow> 
         <Pastors />
         <CounterList />
         <ThemeProvider theme={theme}><Review />
          <Question title={"About Our Church"} text={"know about our church and its related activities"} title1={"How to gain membership"} text1={"Become a member and testify"} title2={"Online Partnerships"} text2={"patner with us and be richly blessed"} />
         
         </ThemeProvider>
        
         
        </AboutContainer></>) 
}

export default About;