import { ThemeProvider } from "styled-components"
import { useTheme } from "styled-components"
import MinistryPage from "./departments/ministryComponent"
import img1 from '../../assets/images/7.jpg';
import img2 from '../../assets/images/5.jpg';
export default function ChildrenMinistry(){
    const theme = useTheme()
    return(
        <ThemeProvider  theme={theme} >
            {/* Children Ministry Page */}
        <MinistryPage
          ministry={{
            name: "Children Ministry",
            heroImage:
              img1,
            overview:
              "Our Children Ministry provides a nurturing environment where kids learn about God's love through fun and engaging activities.",
            mission: "To guide children to grow in their faith and love for God.",
            leadership: [
              {
                name: "Mary Johnson",
                role: "Children's Pastor",
                photo:img2,
                bio: "Mary has been teaching children about Christ for over 5 years with a creative and loving approach.",
                contact: "mary.johnson@example.com",
              },
            ],
            goals: [
              "Teach children about God's love through creative lessons.",
              "Provide a safe and welcoming space for kids to grow spiritually.",
              "Equip children with strong moral values and biblical principles.",
            ],
            event: [
              {
                title: "Vacation Bible School",
                date: "July 10, 2024",
                description:
                  "An exciting week of fun activities and Bible-based learning.",
              },
              {
                title: "Kids Christmas Program",
                date: "December 20, 2024",
                description:
                  "A joyful celebration of Jesus' birth with skits, songs, and activities.",
              },
            ],
            resources: [
              { name: "Children's Devotional Guide", url: "#" },
              { name: "Activity Book for Kids", url: "#" },
            ],
            testimonies: [
              {
                name: "Lisa Brown",
                message:
                  "My children love coming to church! The Children Ministry has truly been a blessing.",
              },
            ],
          }}
        />

      
        </ThemeProvider>
    )
}