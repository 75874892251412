import React from "react";
import { Container, Box, Typography, Link } from "@mui/material";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          p: 4,
          borderRadius: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Page Title */}
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ mb: 4, fontWeight: "bold", color: "#333" }}
        >
          Privacy Policy
        </Typography>

        {/* Introduction */}
        <Typography variant="body1" sx={{ mb: 3 }}>
          Welcome to Tec Global! Protecting your privacy is important to us. This Privacy Policy
          explains how we collect, use, and safeguard your information when you use our app and
          services.
        </Typography>

        {/* Information Collection */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          When you use our app, we may collect the following types of information:
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          - <strong>Personal Information</strong>: Name, email address, phone number, and other information you
          provide when creating an account or contacting us.
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          - <strong>Usage Data</strong>: Information about how you interact with the app, including app usage
          logs, device information, and IP address.
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          - <strong>Cookies and Tracking Technologies</strong>: We may use cookies to improve user experience
          and analyze app performance.
        </Typography>

        {/* Use of Information */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          We use the information we collect to:
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          - Provide, maintain, and improve our app and services.
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          - Respond to your inquiries and communicate updates about your account or services.
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          - Ensure compliance with legal obligations or enforce our app’s terms of service.
        </Typography>

        {/* Data Sharing */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          3. Data Sharing and Disclosure
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          We value your privacy and will never sell your personal information. However, we may
          disclose your information to third parties in the following circumstances:
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          - To comply with legal obligations, such as responding to subpoenas or court orders.
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          - To trusted service providers who assist us in operating the app and providing services
          (e.g., hosting, analytics).
        </Typography>

        {/* Data Security */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          4. Data Security
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          We implement appropriate technical and organizational measures to protect your
          information. However, no method of transmission over the internet or storage system is
          completely secure, and we cannot guarantee absolute security.
        </Typography>

        {/* Account and Data Deletion */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          5. Account Deletion and Data Removal
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          You have the right to request the deletion of your account and personal data at any time.
          To make a request, please visit our{" "}
          <Link
            href="/datadeletionrequest"
            underline="hover"
            sx={{ color: "primary.main", fontWeight: "bold" }}
          >
            Data Deletion Request Page
          </Link>
          . Once your request is processed, all your data will be permanently deleted from our
          systems.
        </Typography>

        {/* Children's Privacy */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          6. Children's Privacy
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Our app is not intended for children under the age of 13. We do not knowingly collect
          personal information from children. If we become aware that we have collected personal
          data from a child, we will delete it immediately.
        </Typography>

        {/* Changes to the Privacy Policy */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          7. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          We may update this Privacy Policy from time to time. Any changes will be posted on this
          page with the updated effective date. Please review this page periodically for updates.
        </Typography>

        {/* Contact Information */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
          8. Contact Us
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          If you have any questions or concerns about this Privacy Policy or your data, please
          contact us at:
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Email:{" "}
          <Link href="mailto:contact@empowermentrevolution.org" underline="hover" sx={{ color: "primary.main" }}>
            contact@empowermentrevolution.org
          </Link>
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Phone: +237 679015081
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Website:{" "}
          <Link href="https://www.empowermentrevolution.org" underline="hover" sx={{ color: "primary.main" }}>
            www.empowermentrevolution.org
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;