import React from 'react'
import LogPage from '../components/login/LoginPage';
import img1 from "../assets/blog/blog2.png";
import { Background } from '../components/HomeContainer.style';
import RegistrationPage from '../components/Registration/signup';
export const registration = () => {
  return (
    <Background>
      <RegistrationPage/>
    </Background>
  )
}


