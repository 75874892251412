
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Question from "../components/FAQ/Questions/Question";

const faq = () => {
  return (
    <Background>
      <Question title={"About Our Church"} text={"know about our church and its related activities"} title1={"How to gain membership"} text1={"Become a member and testify"} title2={"Online Partnerships"} text2={"patner with us and be richly blessed"} />
    </Background>
  );
};

export default faq;
export const Background = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
}));
