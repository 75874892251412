import { Channel, Video } from "../../utils/types/youtubeModel";
import { API_KEY, apiService } from "../../utils/youtubeApi";

export const fetchChannel = async (channelId: string): Promise<Channel> => {
  const response = await apiService.get('/channels', {
    params: {
      part: 'snippet,contentDetails,statistics',
      id: channelId,
      key: API_KEY,
    },
  });

  const data = response.data.items[0];

  const channel: Channel = {
    id: data.id,
    title: data.snippet.title,
    profilePictureUrl: data.snippet.thumbnails.medium.url,
    subscriberCount: data.statistics.subscriberCount,
    videoCount: data.statistics.videoCount,
    uploadPlaylistId: data.contentDetails.relatedPlaylists.uploads,
    videos: [],
  };

  return channel;
};

export const fetchVideosFromPlaylist = async (
  playlistId: string,
  pageToken: string = ""
): Promise<{ videos: Video[]; nextPageToken: string }> => {
  const response = await apiService.get("/playlistItems", {
    params: {
      part: "snippet",
      playlistId,
     
      
      key: API_KEY,
    },
  });

  const data = response.data;

  const videos: Video[] = data.items.map((item: any) => ({
    id: item.snippet.resourceId.videoId,
    title: item.snippet.title,
    thumbnailUrl: item.snippet.thumbnails.high.url,
    channelTitle: item.snippet.channelTitle,
  }));

  return {
    videos,
    nextPageToken: data.nextPageToken || "",
  };
};

export const fetchLiveVideosFromChannel = async (
  channelId: string
): Promise<Video[]> => {
  const response = await apiService.get('/search', {
    params: {
      part: 'snippet',
      channelId,
      eventType: 'live',
      type: 'video',
      key: API_KEY,
    },
  });
console.log('liveVideos data is ', response);
  const videos = response.data.items.map((item: any) => ({
    id: item.id.videoId,
    title: item.snippet.title,
    thumbnailUrl: item.snippet.thumbnails.medium.url,
    channelTitle: item.snippet.channelTitle,
  }));

  return videos;
};