import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {baseUrl} from "../../../utils/baseUrl";
import { PostCategory, AddPostCategory } from "../../../utils/types/blogManagement";




export const getCategories = createAsyncThunk<
  PostCategory[] | null,
  string,
  { rejectValue: string[] }
>("blogCategories", async (token, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${baseUrl()}/v1/aproplat/post_categories`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
  
    return rejectWithValue(["Failed to fetch data"]);
  }
});

export const addPostCategory = createAsyncThunk<
  { message: string; post_category: PostCategory },
  { data: AddPostCategory; token: string },
  { rejectValue: string[] }
>("addBlogCategory", async ({ data, token }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${baseUrl()}/v1/aproplat/post_categories`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
 
    return rejectWithValue(["Failed to create category"]);
  }
});

export const updatePostCategory = createAsyncThunk<
  PostCategory,
  { data: AddPostCategory; token: string; id: number },
  { rejectValue: string[] }
>("updateBlogCategory", async ({ data, token, id }, { rejectWithValue }) => {
  try {
    const response = await axios.patch(
      `${baseUrl()}/v1/aproplat/post_categories/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
  
    return rejectWithValue(["Failed to update category"]);
  }
});

export const deletePostCategory = createAsyncThunk<
  { message: string; id: number },
  { id: number; token: string },
  { rejectValue: string[] }
>("deleteBlogCategory", async ({ id, token }, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      `${baseUrl()}/v1/aproplat/post_categories/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { message: response.data, id };
  } catch (error) {
   
    return rejectWithValue(["Failed to delete category"]);
  }
});
