import React, { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDocs, collection } from "firebase/firestore";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Typography,
  Container,
  Paper,
  Grid,
  InputLabel,
  FormControl,
  styled,
  Box,
} from "@mui/material";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Country, State, City } from "country-state-city";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { db, storage } from "../../redux/firebase";
import { registerUser } from "../../redux/auth/authService";
import { getAuth } from "firebase/auth";
import { updatePassword } from "../../redux/auth/authSlice";

const PhoneInputWrapper = styled(Box)(({ theme }) => ({
  "& .PhoneInput": {
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&:focus-within": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
    },
  },
  "& .PhoneInputInput": {
    border: "none",
    outline: "none",
    width: "100%",
    fontSize: "16px",
    padding: theme.spacing(1),
  },
}));

// Styled Components using Material-UI's `styled` utility
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4), // 2rem equivalent
  marginTop: theme.spacing(3), // 2rem equivalent
  background: "#ffffff",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  borderRadius: 16,
}));

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2), // 1.5rem equivalent
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2), // 1rem equivalent
  padding: theme.spacing(1.5), // 12px equivalent
  fontSize: "16px",
  background: "linear-gradient(45deg, #1976d2, #2196f3)",
  "&:hover": {
    background: "linear-gradient(45deg, #1565c0, #1e88e5)",
  },
}));

const StyledSelect = styled(Select)({
  width: "100%",
});

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1), // 8px equivalent
  fontWeight: 500,
}));

export const RegistrationPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, error, isSuccess } = useAppSelector((state) => state.auth);

  interface FormData {
    fullName: string;
    email: string;
    phone: string;
    password: string;
    password_confirmation: string;
    acceptTerms: boolean;
    isMember: boolean;
    profilePic: File | null;
    membershipId: string;
    churchBranch: string;
    city: string;
    tagline: string;
    country: string;
    state: string;
    address: string;
    dateofbirth: string;
    prayerRequest: string;
    devicetoken: string;
    role: string;
    isVerified: boolean;
    isOnline: boolean;
    isSuplier: boolean;
    mypartnerships: string[];
    lastPaymentDate: Date | undefined;
  }

  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
    acceptTerms: false,
    isMember: false,
    profilePic: null,
    membershipId: "",
    churchBranch: "",
    city: "",
    tagline: "",
    country: "",
    state: "",
    address: "",
    dateofbirth: "",
    prayerRequest: "",
    devicetoken: "",
    role: "member",
    isVerified: false,
    isOnline: false,
    isSuplier: false,
    mypartnerships: [],
    lastPaymentDate: undefined,
  });

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({
        ...formData,
        profilePic: e.target.files[0],
      });
    }
  };


  const handleSubmit = async (e: { preventDefault: () => void }) => {
    console.log("Started");
    e.preventDefault();
    if (formData.password !== formData.password_confirmation) {
      alert("Passwords do not match");
      return;
    }

    try {
      console.log("Started2");
      // Upload profile picture to Firebase Storage
      let profilePicUrl = "";
      if (formData.profilePic) {
        const storageRef = ref(
          storage,
          `userprofilepics/${formData.profilePic.name}`
        );
        await uploadBytes(storageRef, formData.profilePic);
        profilePicUrl = await getDownloadURL(storageRef);
      }
 
      // Prepare user data
      const userData = {
        ...formData,
        profilePic: profilePicUrl,
       
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
        dateofbirth: new Date(formData.dateofbirth), // Convert string to Date
      };

      // Dispatch registerUser action and unwrap the promise
      await dispatch(registerUser(userData)).unwrap();
      const auth = getAuth();
      dispatch(updatePassword(formData.password));
      // Redirect to dashboard after successful registration
      navigate(`/profile/${auth.currentUser?.uid}`);
    } catch (error) {
      // Handle the error
      if (typeof error === "string") {
        console.error("Registration failed:", error);
      } else if (error instanceof Error) {
        console.error("Registration failed:", error.message);
      } else {
        console.error("Registration failed with an unknown error:", error);
      }
    }
  };

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" align="center" gutterBottom>
          Register
        </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
  
              <FormControl fullWidth>
                <InputLabel shrink>Phone Number</InputLabel>
                <PhoneInputWrapper>
                  <PhoneInput
                    international
                    defaultCountry="CM"
                    value={formData.phone}
                    onChange={(value) =>
                      setFormData({
                        ...formData,
                        phone: value?.toString() ?? "",
                      })
                    }
                    required
                  />
                </PhoneInputWrapper>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <input type="file" onChange={handleFileChange} accept="image/*" />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="acceptTerms"
                    checked={formData.acceptTerms}
                    onChange={handleChange}
                    required
                  />
                }
                label="I accept the terms and conditions"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isMember"
                    checked={formData.isMember}
                    onChange={handleChange}
                  />
                }
                label="Register as a member"
              />
            </Grid>

            {formData.isMember && (
              <>
                {/* Country Dropdown */}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <StyledInputLabel>Country</StyledInputLabel>
                    <StyledSelect
                      value={selectedCountry}
                      onChange={(e) =>
                        setSelectedCountry(e.target.value as string)
                      }
                      required
                    >
                      <MenuItem value="" disabled>
                        Select Country
                      </MenuItem>
                      {Country.getAllCountries().map((country) => (
                        <MenuItem key={country.isoCode} value={country.isoCode}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Grid>

                {/* State Dropdown */}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <StyledInputLabel>State</StyledInputLabel>
                    <StyledSelect
                      value={selectedState}
                      onChange={(e) =>
                        setSelectedState(e.target.value as string)
                      }
                      required
                    >
                      <MenuItem value="" disabled>
                        Select State
                      </MenuItem>
                      {State.getStatesOfCountry(selectedCountry).map(
                        (state) => (
                          <MenuItem key={state.isoCode} value={state.isoCode}>
                            {state.name}
                          </MenuItem>
                        )
                      )}
                    </StyledSelect>
                  </FormControl>
                </Grid>

                {/* City Dropdown */}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <StyledInputLabel>City</StyledInputLabel>
                    <StyledSelect
                      value={selectedCity}
                      onChange={(e) =>
                        setSelectedCity(e.target.value as string)
                      }
                      required
                    >
                      <MenuItem value="" disabled>
                        Select City
                      </MenuItem>
                      {City.getCitiesOfState(
                        selectedCountry,
                        selectedState
                      ).map((city) => (
                        <MenuItem key={city.name} value={city.name}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Grid>

                {/* Church Branch Dropdown */}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <StyledInputLabel>Church Branch</StyledInputLabel>
                    <StyledSelect
                      value={formData.churchBranch}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          churchBranch: e.target.value as string,
                        })
                      }
                      required
                    >
                      <MenuItem value="" disabled>
                        Select Branch
                      </MenuItem>
                      {[
                        "Head Quarters",
                        "Buea",
                        "Dubai",
                        "Muyuka",
                        "Yaounde",
                        "Other",
                      ].map((branch) => (
                        <MenuItem key={branch} value={branch}>
                          {branch}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Tagline"
                    name="tagline"
                    value={formData.tagline}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Date of Birth"
                    type="date"
                    name="dateofbirth"
                    value={formData.dateofbirth}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Prayer Request"
                    name="prayerRequest"
                    value={formData.prayerRequest}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <StyledButton
                type="submit"
                variant="contained"
                fullWidth
                disabled={isLoading}
              >
                {isLoading ? "Registering..." : "Register"}
              </StyledButton>
            </Grid>
          </Grid>
        </StyledForm>

        {error && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        {isSuccess && (
          <Typography color="primary" align="center" sx={{ mt: 2 }}>
            Registration successful!
          </Typography>
        )}
      </StyledPaper>
    </Container>
  );
};

export default RegistrationPage;
