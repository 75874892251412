import { createAsyncThunk } from "@reduxjs/toolkit";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { news_Letter } from "../../utils/types";
import { db } from "../firebase";


export const newsLetter = createAsyncThunk<
  news_Letter,
  { email: string }
>("auth/newsLetter", async ({ email }, { rejectWithValue }) => {
  try {
    const docRef = await addDoc(collection(db, "newsletter"), { email });

    return {
      email,
      message: `Subscription successful with ID: ${docRef.id}`,
    };
  } catch (error) {
    return rejectWithValue({
      email,
      message: "Failed to subscribe",
    });
  }
});