import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import {
  Container,
  Typography,
  Paper,
  Avatar,
  Grid,
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Member } from "../../utils/types/userManagement";
import { getAuth } from "firebase/auth";

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  background: "#ffffff",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  borderRadius: 16,
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 130,
  height: 130,
  margin: "0 auto",
  marginBottom: theme.spacing(2),
  boxShadow: `0 4px 10px ${theme.palette.divider}`,
}));

const ProfileField = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  background: theme.palette.grey[100],
  borderRadius: 8,
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
}));

const FieldLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.secondary,
}));

const FieldValue = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1rem",
  color: theme.palette.text.primary,
}));

const ProfilePage = () => {
  const { userId } = useParams(); // Get the user ID from the URL
  const navigate = useNavigate();
  const { user: currentUser } = useAppSelector((state) => state.auth); // Access the current logged-in user from Redux store
  const { isLoading } = useAppSelector((state) => state.users);

  const [userData, setUserData] = useState<Member | null>(null);

  const isCurrentUser = getAuth().currentUser?.uid === userId;

  // State to control the "Not Verified" dialog
  const [openNotVerifiedDialog, setOpenNotVerifiedDialog] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Simulate fetching the user data
        const user = currentUser as Member; // Assume the current user is the profile owner
        setUserData(user);

        // Open the dialog if the user is not verified
        if (user && !user.isVerified) {
          setOpenNotVerifiedDialog(true);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [currentUser, userId]);

  if (isLoading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" mt={8}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!userData) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6" align="center" mt={8}>
          User not found.
        </Typography>
      </Container>
    );
  }

  return (
    <>
      {/* Unclosable Dialog for Not Verified Users */}
      <Dialog
        open={openNotVerifiedDialog}
        aria-labelledby="not-verified-dialog-title"
        disableEscapeKeyDown // Prevent closing with 'Esc'
        onClose={(event, reason) => {
          // Prevent closing with clicks outside the dialog
          if (reason === "backdropClick") return;
        }}
      >
        <DialogTitle id="not-verified-dialog-title" sx={{ textAlign: "center", fontWeight: 700 }}>
          Verification Required
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center", fontSize: "1.1rem", fontWeight: 500 }}>
            You are not yet verified. Please contact the administrator for verification.
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Main Profile Page */}
      <Container maxWidth="md">
        <StyledPaper>
          {/* Profile Header */}
          <Box textAlign="center" mb={4}>
            <ProfileAvatar
              src={userData.profilepic || "/default-avatar.png"}
              alt="Profile Picture"
            />
            <Typography variant="h5" fontWeight={700} gutterBottom>
              {userData.name}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {userData.membershipId || "No email provided"}
            </Typography>
          </Box>

          {/* Profile Details */}
          <Grid container spacing={2}>
            {/* Dynamic fields from user data */}
            {Object.entries(userData)
              .filter(
                ([key]) =>
                  ![
                    "authid",
                    "devicetoken",
                    "lastPaymentDate",
                    "isOnline",
                    "lastseen",
                    "profilepic",
                    "dateofbirth",
                    "dateofregister",
                  ].includes(key)
              )
              .map(([key, value]) => {
                // Function to handle display of values
                const displayValue = (value: string | null | undefined) => {
                  if (value === undefined || value === null || value === "") {
                    return "Not provided";
                  }
                  // Handle boolean values
                  if (typeof value === "boolean") {
                    return value ? "Yes" : "No"; // Or use "True"/"False" if preferred
                  }
                  return value;
                };

                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <ProfileField>
                      <FieldLabel>
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase())}
                      </FieldLabel>
                      <FieldValue>{displayValue(value)}</FieldValue>
                    </ProfileField>
                  </Grid>
                );
              })}

            {Object.entries(userData)
              .filter(([key]) => ["dateofbirth", "dateofregister"].includes(key))
              .map(([key, value]) => {
                // Function to format the date
                const formatDate = (dateString: string | number | Date) => {
                  if (!dateString) return "Not provided";
                  const date = new Date(dateString);
                  return date.toLocaleDateString("en-US", {
                    // Adjust locale and options as needed
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                };

                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <ProfileField>
                      <FieldLabel>
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase())}
                      </FieldLabel>
                      <FieldValue>{formatDate(value)}</FieldValue>
                    </ProfileField>
                  </Grid>
                );
              })}
          </Grid>

          {/* Edit Button */}
          {isCurrentUser && (
            <Box mt={4} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/profile/edit")}
                size="large"
              >
                Edit Profile
              </Button>
            </Box>
          )}
        </StyledPaper>
      </Container>
    </>
  );
};

export default ProfilePage;