import { ThemeProvider } from "styled-components"
import { useTheme } from "styled-components"
import MinistryPage from "./departments/ministryComponent"
import theme from "../../styles/theme";
import img1 from '../../assets/images/7.jpg';
import img2 from '../../assets/images/5.jpg';

export default function MenMinistry(){
    
    return(
        <ThemeProvider  theme={theme} >
            {/* Men's Ministry Page */}
            <MinistryPage
          ministry={{
            name: "Men's Ministry",
            heroImage:
              img1,
            overview:
              "Our Men's Ministry equips men to grow spiritually, lead their families, and serve their communities.",
            mission: "To develop godly men who make a positive impact in the world.",
            leadership: [
              {
                name: "James Smith",
                role: "Men's Pastor",
                photo: img2,
                bio: "James has a heart for mentoring men and helping them discover their God-given purpose.",
                contact: "james.smith@example.com",
              },
            ],
            goals: [
              "Help men grow spiritually and develop strong faith.",
              "Encourage accountability and mentorship among men.",
              "Organize outreach programs to serve the community.",
            ],
            event: [
              {
                title: "Men's Retreat",
                date: "October 15, 2024",
                description:
                  "A weekend for men to recharge spiritually and connect with others.",
              },
              {
                title: "Father's Day Breakfast",
                date: "June 16, 2024",
                description:
                  "Celebrate fatherhood with fellowship and inspiring messages.",
              },
            ],
            resources: [
              { name: "Men's Devotional Guide", url: "#" },
              { name: "Leadership Training Manual", url: "#" },
            ],
            testimonies: [
              {
                name: "Mark Johnson",
                message:
                  "The Men's Ministry has helped me become a better husband and father. It's an amazing brotherhood.",
              },
            ],
          }}
        />  </ThemeProvider>
    )
}