import { Box, Button, MenuItem, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";


export const DropdownButton = styled(Button)(({ theme }) => ({
  background: "none",
  color: "#fff",
  px: 1,
  fontWeight: 300,
  // fontSize: "16px",
  textTransform: "capitalize",
  padding: "5px 20px",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    // padding: "10px 13px",
  },
}));

export const ButtonContainer = styled(Box)(() => ({
  display: "flex",
  px: 5,
  alignItems: "center",
}));

export const AccountImage = styled(Box)({
  width: 40,
  height: 40,
  padding: "5px",
  borderRadius: "100px",
});

export const StyledPaper = styled(Paper)({
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  marginTop: "50px",
  marginRight: "20px",
  width: 180,
  height: "auto",
});

export const CustomMenuItem = styled(MenuItem)({
  padding: "10px 20px",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: '400',
});

export const NavLink = styled(Link)({
  color: "inherit",
});
